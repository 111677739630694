import React, { PureComponent } from "react";
import { tickerList } from "../apis/home-api";
import InnerInsights from "../components/common/landing/home-insights";
import Contact from "../components/common/landing/contact";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VisibilitySensor from "react-visibility-sensor";
import MetaTags from 'react-meta-tags';
// import ResourcePage from "../components/common/landing/resourcePage";
 import "../assets/styles/landing.scss";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import MailChimpForm from "./MailChimp";
import MainCarousel from "../components/common/CardCarousel/MainCarousel";
import { TypeAnimation } from 'react-type-animation';
const numeral = require("numeral");


const settings = {
  // dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 1500,
  pauseOnHover: true,
};

class LandingPage extends PureComponent {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      formData: {},
      sender_name: "",
      focus: false,
    };
    this.handleChange = this.handleChange.bind(this);
    AOS.init({
      once: true
    });
  }

  componentDidMount() {
    tickerList()
      .then((res) => {
        this.setState({ formData: res.data, isLoading: false });
      })
      .catch((err) => {
        throw err;
      });
  }

  refreshPage() {
    window.location.reload(false);
  }

  getStarted = () => {
    window.location.replace("api-docs");
  };

  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  redirectToSubscribe = () => {
    this.props.history.push("/subscribe");

    // this.props.history.push({
    //       pathname: '/subscribe',
    //       state: { email: this.state.sender_name }
    //     });
  };
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ focus: true });
    }
   
  };

  render() {
    const { formData, focus } = this.state;
    const eventsNum = parseInt(
      formData.event_count ? formData.event_count : "6,374"
    );
    const imprisomentNum = parseInt(
      formData.total_imprisonment ? formData.total_imprisonment : "583568"
    );
    const individualNum = parseInt(
      formData.individual_count ? formData.individual_count : "8,753"
    );
    const organizationsNum = parseInt(
      formData.entity_count ? formData.entity_count : "1,696"
    );
    const regulatorsNum = parseInt(
      formData.regulator_count ? formData.regulator_count : "825"
    );
    const countryNum = parseInt(
      formData.country_count ? formData.country_count : "251"
    );
    const sourcesNum = parseInt(formData.sources ? formData.sources : "7,097");
    const fineNum= parseInt((formData.total_fine? numeral(formData.total_fine).format("0,00 a"): "104.45"));
    return (
      <>
        <div className="home-page-conatiner">
        <MetaTags>
        <meta name="keywords" content="Aml Platform, Anti Money Laundering Solutions, Aml Money Laundering"/> 
        <meta name="description" content="AML Penalties is the most comprehensive and inclusive money laundering events & action-based data asset for AML professionals, organizations & regulators."/>
          <title>AML Penalties - Anti Money Laundering Platform & Solutions</title>
        <meta id="url" property="og:url" content='https://www.amlpenalties.com'/>
        </MetaTags>
          {/*<Home /> */}
      
          <div className="landing-page-conatiner padding-top-130">
            <section className="section-1 ">
              <div className="container container-xl-aml lp-main-container ">
                <div className="row">
                  <div className="col-md-7 col-sm-3 pt-110">
                   {/* <div>
                   <TypeAnimation
                    sequence={[
              " The Anti-Money Laundering Data Platform",
              
        1000,
        "The most comprehensive money laundering events-based data platform",
        1000,
        "Built for AML & financial crimes compliance professionals, organizations & regulators.",
        1000,
       
      ]}
     // wrapper="span"
      speed={50}
      style={{ fontSize: '3em' }}
      repeat={Infinity}
    />
                   </div> */}
                    {/*<p className="font-color-grey font-size-17 font-weight-500 title-heading">ANTI-MONEY LAUNDERING</p> */}
                    <h1 className="Early-Access-Release" data-aos="fade-up" data-aos-duration="1250">
                      The Anti-Money Laundering Data Platform
                    </h1>
                    <p className="font-color-grey section-1-title font-weight-500 font-size-17 " data-aos="fade-up" data-aos-duration="1250">
                      The most comprehensive money laundering events-based data
                      platform, built for AML & financial crimes compliance
                      professionals, organizations & regulators.
                    </p>
                    {/* <div className="early-h font-size-22 font-weight-bold">
                      Early Access
                    </div> */}
                         <button
                      className="btn btn-primary sm-primary sm-primary-1 font-blue Sign-up-for-Free-2 my-3 mr-4" 
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                     Book a Discovery Call
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free"
                      onClick={() =>
                        window.location.replace(
                          "https://app.amlpenalties.com/sign-up"
                        )
                      }
                    >
                      Sign Up For Free
                    </button>
                  </div>
                  <div className="col-md-5 col-sm-5 img-div">
                    {/* <img
                      // src={require("../assets/images/new-images/dashboard-img/Group 75.svg")} 
                      src={require("../assets/Landing page icons/001.svg")} 
                      alt='AML Fine'
                    /> */}
                    <object data={require("../assets/Landing page icons/AML-Penalties-v1.svg")}></object>
                    {/*<div class="embed-responsive embed-responsive-16by9">
                          <iframe class="embed-responsive-item video-div" src="https://d2utazo5ebtbf3.cloudfront.net/AML_Landing_video.mp4" allowfullscreen></iframe>
                        </div> */}

                    {/*<video id="video-background" controls width="450" height="400" muted loop poster={require("../assets/images/landing/13169.jpg")} >
                            <source src="https://d2utazo5ebtbf3.cloudfront.net/AML_Landing_video.mp4" type="video/mp4" />
                         </video> */}

                    {/*<Slider {...settings}>
                            <div>
                              <img src={require("../assets/images/new-images/dashboard-img/pexels-david-mcbee-730547 (1).jpg")} />
                            </div>
                            <div>
                              <img src={require("../assets/images/new-images/dashboard-img/josh-appel-NeTPASr-bmQ-unsplash.jpg")} />
                            </div>
                            <div>
                              <img src={require("../assets/images/new-images/dashboard-img/ohtilly-2iu_qKPbjSk-unsplash.jpg")} />
                            </div>
                            <div>
                              <img src={require("../assets/images/new-images/dashboard-img/mufid-majnun-LVcjYwuHQlg-unsplash.jpg")} />
                            </div>
                            <div>
                              <img src={require("../assets/images/new-images/dashboard-img/pexels-michael-steinberg-(1).jpg")} />
                            </div>
                            <div>
                              <img src={require("../assets/images/new-images/dashboard-img/roman-synkevych-tyhso4u6GDo-unsplash.jpg")} />
                            </div>
                            <div>
                              <img src={require("../assets/images/new-images/dashboard-img/pexels-pixabay-259027 (1).jpg")} />
                            </div>
                            <div>
                              <img src={require("../assets/images/new-images/dashboard-img/eduardo-soares-utWyPB8_FU8-unsplash.jpg")} />
                            </div>
                            <div>
                              <img src={require("../assets/images/new-images/dashboard-img/tingey-injury-law-firm-veNb0DDegzE-unsplash.jpg")} />
                            </div>
                            <div>
                              <img src={require("../assets/images/new-images/dashboard-img/scott-graham-5fNmWej4tAA-unsplash.jpg")} />
                            </div>
                          </Slider> */}
                  </div>
                </div>
              </div>
            </section>

            <section className="section-2 section-grey">
              <div className="container lp-main-container ticker-main-div pt-30 pb-50" data-aos="fade-up" data-aos-duration="1250">
                <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center pb-20">
                  <h2 className="Made-For-a-Purpose">Coverage</h2>
                </div>
                <div>
                  <VisibilitySensor
                    onChange={this.onVisibilityChange}
                    partialVisibility={true}
                  >
                    <div>
                      <div className="row row-mt">
                        <div className="col-lg-2 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                          <div className="ticker-sm">
                            <img
                              src={require("../assets/images/new-images/group-28@2x.png")} alt='Events'
                            />
                            <span className="ticker-num">
                              <CountUp
                                end={focus ? eventsNum : null}
                                duration={3}
                                separator=","
                                redraw={true}
                              />
                            </span>
                          </div>
                          <div className="ticker-title font-size-12">
                            <span>Events</span>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                          <div className="ticker-sm">
                            <img
                              src={require("../assets/images/new-images/group-8-copy@2x.png")} alt='Penalties'
                            />
                            <span className="ticker-num">
                              <CountUp
                                end={
                                  focus
                                    ?fineNum
                                    : null
                                }
                                duration={3}
                                // decimals={2}
                                // decimal="."
                              />
                            </span>
                          </div>
                          <div className="ticker-title font-size-12">
                            <span>Penalties (in Bn USD)</span>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-4 mb-4 mb-md-0 col-wd-2 text-center ">
                          <div className="ticker-sm">
                            <img
                              src={require("../assets/images/new-images/group-8-copy_2@2x.png")} alt='Imprisonment'
                            />
                            <span className="ticker-num">
                              <CountUp
                                end={focus ? imprisomentNum : null}
                                duration={3}
                                separator=","
                              />
                            </span>
                          </div>
                          <div className="ticker-title font-size-12">
                            <span>Imprisonments (in Months)</span>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-4 mb-4 mb-md-0 col-wd-2 text-center ">
                          <div className="ticker-sm">
                            <img
                              src={require("../assets/images/new-images/group-8-copy_3@3x.png")} alt='Individual'
                            />
                            <span className="ticker-num">
                              <CountUp
                                end={focus ? individualNum : null}
                                duration={3}
                                separator=","
                              />
                            </span>
                          </div>
                          <div className="ticker-title font-size-12">
                            <span>Individuals</span>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-4 mb-4 mb-md-0 col-wd-2 text-center ">
                          <div className="ticker-sm">
                            <img
                              src={require("../assets/images/new-images/group-8-copy_4@2x.png")} alt='Organizations'
                            />
                            <span className="ticker-num">
                              <CountUp
                                end={focus ? organizationsNum : null}
                                duration={3}
                                separator=","
                              />
                            </span>
                          </div>
                          <div className="ticker-title font-size-12">
                            <span>Organizations</span>
                          </div>
                        </div>
                      </div>

                      <div className="row row-mt pt-80">
                        <div className="col-lg-2 col-md-4 mb-4 mb-md-0 col-wd-2 text-center ">
                          <div className="ticker-sm">
                            <img
                              src={require("../assets/images/new-images/group-8-copy_5@3x.png")} alt='Regulator & Agencies'
                            />
                            <span className="ticker-num">
                              <CountUp
                                end={focus ? regulatorsNum : null}
                                duration={3}
                                separator=","
                              />
                            </span>
                          </div>
                          <div className="ticker-title font-size-12">
                            <span>Regulators & Agencies</span>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-4 mb-4 mb-md-0 col-wd-2 text-center ">
                          <div className="ticker-sm">
                            <img
                              src={require("../assets/images/new-images/group-8-copy_6@2x.png")} alt='Sources'
                            />
                            <span className="ticker-num">
                              <CountUp
                                end={focus ? sourcesNum : null}
                                duration={3}
                                separator=","
                              />
                            </span>
                          </div>
                          <div className="ticker-title font-size-12">
                            <span>Sources</span>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-4 mb-4 mb-md-0 col-wd-2 text-center ">
                          <div className="ticker-sm">
                            <img
                              src={require("../assets/images/new-images/group-8_2.png")} alt='Countries & Jurisdictions'
                            />
                            <span className="ticker-num">
                              <CountUp
                                end={focus ? countryNum : null}
                                duration={3}
                                separator=","
                              />
                            </span>
                          </div>
                          <div className="ticker-title font-size-12">
                            <span>Countries & Jurisdictions</span>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-4 mb-4 mb-md-0 col-wd-2 text-center ">
                          <div className="ticker-sm">
                            <img
                              src={require("../assets/images/new-images/group-8_6.png")} alt='Years'
                            />
                            <span className="ticker-num">
                              <CountUp end={focus ? 22 : null} duration={2} />
                            </span>
                          </div>
                          <div className="ticker-title font-size-12">
                            <span>Years</span>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-4 mb-4 mb-md-0 col-wd-2 text-center ">
                          <div className="ticker-sm">
                            <img
                              src={require("../assets/images/new-images/group-8-copy_7@2x.png")} alt='Data points'
                            />
                            <span className="ticker-num">
                              <CountUp end={focus ? 1 : null} duration={2} />{" "}
                              Mn+
                            </span>
                          </div>
                          <div className="ticker-title font-size-12">
                            <span>Data Points</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </VisibilitySensor>
                </div>
              </div>
            </section>
            {/* <section className="section-2">
              <div className="container lp-main-container  pt-80 pb-80" data-aos="fade-up" data-aos-duration="1250">
                <MainCarousel />
               
              </div>
            </section> */}
            {/*<section className="section-grey"> */}
            <section className="section-2 blue-section">
              <div className="container lp-main-container" data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt pt-50 pb-50">
                  <div className="brand-tag">
                    <p className="font-size-20 font-color-white">
                      The most comprehensive money laundering event-based data
                      platform, built for AML & financial crimes compliance
                      professionals, organizations & regulators. This platform
                      has been built to aid in the fight against money
                      laundering and financial crime risk
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-2">
              <div className="container lp-main-container pt-50 pb-50" data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt">
                  <div className="purpose">
                    <h2 className="Made-For-a-Purpose">Made for Purpose</h2>
                    <p>
                      AML, Risk & Banking Professionals have limited access to
                      data, information and tools to combat money laundering,
                      effectively. Hence, a need developed for a single resource
                      that aggregates publicly-available, unstructured and
                      scattered Money Laundering related events, cases and
                      records in a manner that is well-defined, categorized and
                      structured for use by all stakeholders.
                    </p>
                  </div>
                </div>
                <div className="row row-mt pt-80">
                  <div className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../assets/images/new-images/group-8.png")} alt='AML Risk Check'
                      />
                      <div className="purpose-heading font-size-14">
                        AML Risk Checks
                      </div>
                      <div className="purpose-grey-text font-size-12">
                        Validation, checks and identification of Individuals,
                        Businesses and Entities which may have been
                        implicated/involved in Money Laundering cases.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../assets/images/new-images/group-8_2.png")}  alt='Correspondent Banking'
                      />
                      <div className="purpose-heading font-size-14">
                        Correspondent Banking
                      </div>
                      <div className="purpose-grey-text font-size-12">
                        Assessment of Banking relationships on an ongoing and
                        regular basis by understanding their exposure to AML
                        actions or Money Laundering Events.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../assets/images/new-images/group-8_3.png")} alt='Monitoring'
                      />
                      <div className="purpose-heading font-size-14">
                        Monitoring
                      </div>
                      <div className="purpose-grey-text font-size-12">
                        Monitoring of the most relevant risk, cases, AML
                        events, laws, regulations, regions etc.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mt pt-80">
                  <div className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../assets/images/new-images/111.png")} alt='Program & Planning'
                      />
                      <div className="purpose-heading font-size-14">
                        Program & Planning
                      </div>
                      <div className="purpose-grey-text font-size-12">
                        Critical inputs towards managing AML programs,
                        guidelines, standards, reporting, relationships and
                        potential/current clients.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../assets/images/new-images/group-8_5.png")} alt='Due Diligence'
                      />
                      <div className="purpose-heading font-size-14">
                        Due Diligence
                      </div>
                      <div className="purpose-grey-text font-size-12">
                        Focused inputs, news, analysis and information which may
                        be critical for AML teams and due diligence operations
                        on clients & partners.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../assets/images/new-images/group-8_6.png")} alt='Compliance & Risk Teams'
                      />
                      <div className="purpose-heading font-size-14">
                        Compliance & Risk Teams
                      </div>
                      <div className="purpose-grey-text font-size-12">
                        Managing teams, resources, training, standards and
                        ensuring alignment with best practices/ information on
                        an ongoing basis.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-2 blue-section">
              <img
                className="img-top"
                src={require("../assets/images/new-images/top.png")}
              />
              <img
                className="img-bottom"
                src={require("../assets/images/new-images/bottom.png")}
              />
              <div className="container lp-main-container" data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt pt-80">
                  <div className="purpose">
                    <h2 className="Made-For-a-Purpose font-color-white">
                      Benefits
                    </h2>
                    <p className="font-color-white">
                      AML penalties are a single source of information on all
                      AML events, cases, sources, and regulatory actions. The
                      data asset will help AML professionals and financial
                      institutes to screen a customer/vendor/business
                      partners/associated parties and monitor AML risks
                      pertained to them.
                    </p>
                  </div>
                </div>
                <div className="row row-mt pt-80">
                  <div className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../assets/images/new-images/group-8-7.png")}  alt='Enhanced AML Assurance'
                      />
                      <div className="purpose-heading font-color-white font-size-14">
                        Enhanced AML Assurance
                      </div>
                      <div className="purpose-grey-text font-color-white font-size-12">
                        With our comprehensive data, information, & tools on
                        combating money laundering, our clients can secure
                        enhanced AML assurance.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../assets/images/new-images/group-29@2x.png")} alt='Reduced Diligence Time'
                      />
                      <div className="purpose-heading font-color-white font-size-14">
                        Reduced Diligence Time
                      </div>
                      <div className="purpose-grey-text font-color-white font-size-12">
                        We leverage smart technology to provide focused inputs,
                        news, analytics and information on AML events, laws &
                        regulations, thereby reduce diligence time.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../assets/images/new-images/group-8-h.png")} alt='Compliance Assistance'
                      />
                      <div className="purpose-heading font-color-white font-size-14">
                        Compliance Assistance
                      </div>
                      <div className="purpose-grey-text font-color-white font-size-12">
                        We assist with regulatory compliance by managing teams,
                        resources, & training standards in the AML landscape.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mt pt-80 pb-80">
                  <div className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../assets/images/new-images/group-8-t.png")} alt='High Risk Checks'
                      />
                      <div className="purpose-heading font-color-white font-size-14">
                        High Risk Checks
                      </div>
                      <div className="purpose-grey-text font-color-white font-size-12">
                        We protect the financial integrity of our clients by
                        providing solutions for high-risk checks in the AML
                        landscape and help achieve compliance effectively &
                        efficiently.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../assets/images/new-images/group-8-10.png")} alt='Quick Assessments'
                      />
                      <div className="purpose-heading font-color-white font-size-14">
                        Quick Assessments
                      </div>
                      <div className="purpose-grey-text font-color-white font-size-12">
                        We provide a quick assessment of real-time
                        money-laundering enterprise risks & help achieve better
                        AML compliance.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../assets/images/new-images/group-8_11.png")} alt='Research Support'
                      />
                      <div className="purpose-heading font-color-white font-size-14">
                        Research Support
                      </div>
                      <div className="purpose-grey-text font-color-white font-size-12">
                        We assist our clients in the research of effective AML
                        solutions for their enterprises.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-2 centa-mob">
              <div className="container lp-main-container pt-50 pb-50" data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt pl-85">
                  <div className="monitoring-tag">
                    {/*<p className="service-tag">SERVICES</p> */}
                    <h2 className="Made-For-a-Purpose">Monitoring</h2>
                    <p className="mtr-pl">
                      Effectively monitor real-time consolidated AML data sets &
                      provide daily updates on financial crime insights,
                      regulations, and AML cases, thereby provide effective AML
                      solutions with strict regulatory compliance.
                    </p>
                  </div>
                </div>
                <div className="row row-mt pt-80 pl-85">
                  <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
                    <div className="mtr-sm">
                      <span className="">
                        <img
                          src={require("../assets/images/new-images/refresh-button.png")}  alt='Daily Updates'
                        />
                      </span>
                      <div className="mtr-text">
                        <div className="mtr-heading font-size-16">
                          Daily Updates
                        </div>
                        <div className="mtr-grey-text font-size-13">
                          Get regular updates on the AML related events on a
                          day-to-day basis.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
                    <div className="mtr-sm">
                      <span className="">
                        <img
                          src={require("../assets/images/new-images/clock.png")} alt='News Regulation'
                        />
                      </span>
                      <div className="mtr-text">
                        <div className="mtr-heading font-size-16">
                          News Regulation
                        </div>
                        <div className="mtr-grey-text font-size-13">
                          Access the latest national & international financial
                          regulations in the AML landscape.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mt pt-80 pl-85">
                  <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
                    <div className="mtr-sm">
                      <span className="">
                        <img 
                          src={require("../assets/images/new-images/file.png")} alt='Latest AML Cases'
                        />
                      </span>
                      <div className="mtr-text">
                        <div className="mtr-heading font-size-16">
                          Latest AML Cases
                        </div>
                        <div className="mtr-grey-text font-size-13">
                          Get instant access to the latest updates on AML &
                          AML-related cases.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
                    <div className="mtr-sm">
                      <span className="">
                        <img
                          src={require("../assets/images/new-images/gavel-24-px_2.png")} alt='Actions & Penalties'
                        /> 
                      </span>
                      <div className="mtr-text">
                        <div className="mtr-heading font-size-16">
                          Actions & Penalties
                        </div>
                        <div className="mtr-grey-text font-size-13">
                          Access a comprehensive database on the latest actions
                          and penalties in the AML landscape.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-grey">
              <div className="container lp-main-container" data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt pt-50 pb-50">
                  <div className="col-lg-5 col-md-5 mb-4 mb-md-0 col-wd-2">
                    <div className="feature-main">
                      {/*<p className="feature font-size-12">Features</p>*/}
                      <h3 className="Made-For-a-Purpose">Dashboard</h3>
                      <p className="feature-pl font-size-12">
                        Dashboard for Interactive Visualizations and
                        Infographics for an effective understanding of money
                        laundering risks and events, association map, regulatory
                        actions and reasoning.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7 mb-4 mb-md-0 col-wd-2">
                    <img
                      className="feature-img"
                      src={require("../assets/images/new-images/dashboard.png")} alt='Dashboard'
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="section-2">
              <div className="container lp-main-container" data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt pt-50 pb-50">
                  <div className="col-lg-5 col-md-5 mb-4 mb-md-0 col-wd-2">
                    <div className="feature-main">
                      {/*<p className="feature font-size-12">Features</p> */}
                      <h2 className="Made-For-a-Purpose">
                        Rich Case Analytics
                      </h2>
                      <p className="feature-pl font-size-12">
                        With Rich Case Analytics, you can get access to millions
                        of data points on the country/event-specific analytics &
                        their related entities, individuals, penalties,
                        imprisonments as well as regulating agencies in our
                        interactive user-friendly visual dashboard.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7 mb-4 mb-md-0 col-wd-2">
                    <img
                      className="feature-img"
                      src={require("../assets/images/new-images/dashboard-img/virtual.jpg")} alt='Rich Case Analytics'
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="section-grey">
            <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center pb-20">
                  <h2 className="Made-For-a-Purpose">Resources</h2>
                </div>
              <div className="container lp-main-container">
                <InnerInsights  showFour={true}/>
                {/* <ResourcePage /> */}
              </div>
            </section>
            {/* <section className="section-2 ">
                     <div className="container lp-main-container">
                         <SimpleSlider />
                     </div>
              </section> */}
            {/* <section className="section-2">
              <div className="container lp-main-container">
                <div className="row row-mt pt-50">
                  <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
                    <img
                      className="new-letter-img"
                      src={require("../assets/images/new-images/Bloggers attracting audience and getting money.jpg")} alt='Newsletter'
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
                    <div className="feature-main">
                      <h2 className="Made-For-a-Purpose">
                        Subscribe To Our Newsletter
                      </h2>
                      <p className="feature-pl font-size-12">
                        Get notified about company updates, news and blog posts.
                        We hate spam.
                      </p>
                    </div>
                    <div className="form-inline get-statred"> */}
                      {/*<input type="text" className="form-control mr-1"
                              name="sender_name"
                              id="support-form-name"
                              onChange={this.handleChange}
                              placeholder="Email Address*"
                             /> */}
                      {/* <button
                        className="btn btn-primary started-btn"
                        onClick={() => this.redirectToSubscribe()}
                      >
                        Get Started
                      </button> */}

                      {/*<div id="mc_form">
                                 <Mailchimp
                                   action='https://amlpenalties.us20.list-manage.com/subscribe/post?u=d454f63cc9f49ba8caa3b7922&amp;id=fc12608fdf'
                                   fields={[
                                     {
                                       name: 'MMERGE1',
                                       placeholder: 'First Name*',
                                       type: 'text',
                                       required: false,
                                     },
                                     {
                                       name: 'MMERGE2',
                                       placeholder: 'Last Name*',
                                       type: 'text',
                                       required: false,
                                     },
                                     {
                                       name: 'EMAIL',
                                       placeholder: 'Email Address*',
                                       type: 'email',
                                       required: true
                                     }
                                   ]}
                                   messages = {
                                     {
                                       sending: "Sending...",
                                       success: "Thank you for subscribing!",
                                       error: "An unexpected internal error has occurred.",
                                       empty: "You must write an e-mail.",
                                       duplicate: "Too many subscribe attempts for this email address",
                                       button: "Get Started"
                                     }
                                   }
                                   className='subscribe-hmc'
                                 />
                             </div> */}
                    {/* </div>
                  </div>
                </div>
              </div>
            </section> */}

            <section className="section-2">
              <div className="container lp-main-container pb-50 pt-50" data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt">
                  <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                    <h2 className="Made-For-a-Purpose">AML Penalties API</h2>
                    {/*<p className="aml-api-pera">Early stage compan? Eligible applicants get all of our products for just $49 a month.</p> */}
                    <div className="btn-pt-25">
                      <button
                        className="btn btn-primary get-start-btn"
                        onClick={() => {
                          this.getStarted();
                        }}
                      >
                        Get Started
                      </button>
                      {/*<button className="btn btn-primary">Learn More</button> */}
                    </div>
                  </div>
                </div>
                <div className="row row-mt pt-80">
                  <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="card sm-card">
                      <div className="card-body">
                        <img
                          className="card-img-top"
                          src={require("../assets/images/new-images/access.jpg")} 
                          alt="Card image"
                        />
                        <p className="card-text font-size-14">
                          Access to 1 million data points.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="card sm-card">
                      <div className="card-body">
                        <img
                          className="card-img-top"
                          src={require("../assets/images/new-images/easy.png")}
                          alt="Card image"
                        />
                        <p className="card-text font-size-14">
                          Easy registration & UI integration.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="card sm-card">
                      <div className="card-body">
                        <img
                          className="card-img-top"
                          src={require("../assets/images/new-images/average.jpg")}
                          alt="Card image"
                        />
                        <p className="card-text font-size-14">
                          2-4 Average search call time.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="card sm-card">
                      <div className="card-body">
                        <img
                          className="card-img-top"
                          src={require("../assets/images/new-images/free.png")}
                          alt="Card image"
                        />
                        <p className="card-text font-size-14">
                          Free trial of 10 searches per day.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <section className="section-grey text-justify">  */}
            <section className="section-2 blue-section text-justify"> 
                 {/* <img
                className="img-top"
                src={require("../../assets/images/new-images/top.png")}
              />
              <img
                className="img-bottom"
                src={require("../../assets/images/new-images/bottom.png")}
              /> */}
                <div className="container lp-main-container-2" data-aos="fade-up" data-aos-duration="1250">
                <div className="row">
                  <div className="col-lg-6 col-sm-12 img-div text-center d-flex align-tems-center">
                  <object data={require("../assets/Landing page icons/AML-Penalties-v2.svg")}></object>
                  </div>
                  <div className="col-lg-6 col-sm-12 pt-50 ">
                    <div className="max-width px-3" >
                    {/*<p className="font-color-grey font-size-17 font-weight-500 title-heading">ANTI-MONEY LAUNDERING</p> */}
                    <h1 className="fontrandom text-left" >
                       Still have question?<br/><span> Connect </span>with us
                    </h1>
                    <div className="my-3">
                      <div className="d-flex listGroup mb-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div className=" text-left">
                          <h5 className="listItem">Explore our Technology and Data Asset Solutions</h5>
                        </div>
                      </div>
                      <div className="d-flex listGroup my-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div  className=" text-left">
                          <h5 className="listItem">Get Pricing Information</h5>
                        </div>
                      </div>
                      <div className="d-flex listGroup mt-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div  className=" text-left">
                          <h5 className="listItem">Free Trial or Demo</h5>
                        </div>
                      </div>
                    </div>
                    {/* <div className="early-h font-size-22 font-weight-bold">
                      Early Access
                    </div> */}
                     <button
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free-2 my-3 mr-4" 
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                     Book a Discovery Call
                    </button>
                    <button
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free purple-bg my-3"
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                     Book a Free Demo 
                    </button>
                    </div>
                    </div>
                </div>
              </div>
            </section>
            <section className="p-5">
              <div className="" data-aos="fade-up" data-aos-duration="2000" data-aos-once="true" data-aos-offset="300">
                <div className="lp-main-container-2 container ">
                <div className="row">
                  <div className="col-md-7 col-sm-12 d-flex align-items-center  justify-content-center pr-0">
                    <h2 className="WeeklyNewsletter">Subscribe to our weekly newsletter</h2>
                 </div>
                 <div className="col-md-5 col-sm-12 d-flex align-items-center  justify-content-center pl-0">
                 <a className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free" style={{width:"350px"}} href="https://zcmp.in/i4ck" target="_blank">SUBSCRIBE HERE</a>
                 </div>
                 </div>
              </div>
              </div>
            </section>

            {/* <section className="section-2">
              <div className="container lp-main-container">
                <div className="row row-mt pt-50 pb-50">
                  <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                    <h2 className="Made-For-a-Purpose">Contact Us</h2>
                  </div>
                </div>
                <div className="row row-mt pt-50">
                  <Contact pageName="contact-us" />
                </div>
              </div>
            </section> */}
          </div>
         
        </div>
      </>
    );
  }
}

export default LandingPage;
