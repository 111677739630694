import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { LetestInsights } from '../../../apis/home-api';
import Loader from "../../../components/common/loader/bar-loader";
import ReactGA from 'react-ga';

const INSIGHTTYPE = {
  WEEKLY: 'Weekly Bulletin',
  CASEFILE: 'Case Files',
  SUBJECT: 'The Subject Matter',
  SPOTLIGHT:"SpotLight Event",
  MONTHLY:"Monthly Update",
  Quaterly:"Quarterly Update",
  REGULATORY:"Regulatory Bulletin"
};

const QOUTE = {
  WEEKLYQ: 'Weekly Summary of notable AML related cases and penalties, regulatory updates in the News',
  CASEFILEQ: 'Important case details, modus operandi and sentencing of known public figures implicated under AML Laws globally',
  SUBJECTQ: 'Introduction and essential details about the latest AML updates globally',
  SPOTLIGHTQ:' Major money laundering event that highlights substantial breaches and non compliance with AML/CFT regulations',
  MONTHLYQ:'Monthly Infographic of AML related regulatory updates, cases, and penalties',
  QUATERLYQ:'Quarterly Infographic of AML related regulatory updates, cases, and penalties',
  REGULATORYQ:'Fortnight newsletter covering the latest developments, regulations and updates.',
};

class HomeInsights extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      insightList:[],
      requestInProgesss: false,
    };
  }

  componentDidMount(){
     this.setState({ requestInProgesss: true});
     LetestInsights()
      .then(res => {
        this.setState({ insightList: res.data, requestInProgesss: false });
      })
      .catch(err => {
        throw err;
      });
  }

  setType=(e, data)=>{
    if(data==="Monthly Update"||data==="Quarterly Update"){
      window.sessionStorage.setItem('insight-type', "Other Updates");
    }else{
    window.sessionStorage.setItem('insight-type', data);
    }
  }


  render() {

    const {insightList} = this.state;

    return (
      <>

   {/*<section className="wow fadeIn light insight" style={{background:"#fff",position:"relative"}}>
     <div className="overlays">
       <div className="container-fluid">
           <div className="row">
              <h2 style={{margin:"-25px 0 40px 0"}} className="Sectionhead">Insights</h2>

              { !this.state.requestInProgesss && insightList.length>0 && insightList.map((item, i) =>{

                  return (
                    <div key={i} className="col-md-3 col-sm-3 col-xs-12 sm-margin-30px-bottom wow fadeInUp mb-20" style={{visibility: "visible", animationName: "fadeInUp"}}>
                        <div className="feature-box-16 xs-width-100">
                            <img src={item.image_url} alt="" data-no-retina="" />
                            <div className="feature-box-content-nl text-center">
                                <div className="opacity-full-dark bg-extra-dark-gray"></div>
                                <div className="display-table height-100 width-100 position-relative">
                                    <div className="vertical-align-middle display-table-cell padding-10px-lr padding-20px-tb">
                                       <Link to="/insights" className="text-light-gray width-86 mx-auto" onClick={(e)=>this.setType(e, item.type)}>
                                       {INSIGHTTYPE.WEEKLY === item.type &&
                                          <div>
                                            {QOUTE.WEEKLYQ}
                                          </div>
                                       }
                                       {INSIGHTTYPE.CASEFILE === item.type &&
                                          <div>
                                            {QOUTE.CASEFILEQ}
                                          </div>
                                       }
                                       {INSIGHTTYPE.SUBJECT === item.type &&
                                          <div>
                                            {QOUTE.SUBJECTQ}
                                          </div>
                                       }

                                       </Link>
                                         <br/>
                                         <Link className="btn btn-small btn-small-padding btn-white mt-2 font-weight-300 btn-rounded" to="/insights" onClick={(e)=>this.setType(e, item.type)}>Go To {item.type} <i className="ti-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  )

                 })
              }

                { (insightList && insightList.length>0 && !this.state.requestInProgesss) &&
                  <div className="col-md-3 col-sm-3 col-xs-12 sm-margin-30px-bottom wow fadeInUp mb-20" style={{visibility: "visible", animationName: "fadeInUp"}}>
                     <div className="feature-box-16 xs-width-100 vs-img">
                         <img src={require("../../../assets/images/landing/clipboard-img.jpg")} alt="" data-no-retina="" />
                         <div className="feature-box-content-nl text-center">
                             <div className="opacity-full-dark bg-extra-dark-gray"></div>
                             <div className="display-table height-100 width-100 position-relative">
                                 <div className="vertical-align-middle display-table-cell padding-10px-lr padding-20px-tb">
                                 <Link to="/insights" className="text-light-gray width-86 mx-auto" onClick={(e)=>this.setType(e, 'Visualization')}>Coverage of the lates global AML events, actions, penalties, and regulatory updates - Subscribe & stay updated.</Link>
                                   <br/>
                                   <Link className="btn btn-small btn-small-padding btn-white mt-2 font-weight-300 btn-rounded" to="/insights" onClick={(e)=>this.setType(e, 'Visualization')}>Go To Visualization <i className="ti-arrow-right"></i></Link>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
                }

                {this.state.requestInProgesss && (
                  <div className="col-md-12 col-sm-12 col-xs-12 sm-margin-30px-bottom wow fadeInUp mb-20" style={{visibility: "visible", animationName: "fadeInUp"}}>
                    <div className="text-center">
                      <Loader loading />
                    </div>
                  </div>
                )}
           </div>
       </div>
      </div>
   </section> */}

     <div className="row py-20 ">
 
{ this.state.requestInProgesss?<div className=' col-12 text-center mt-5'><Loader loading/></div>:<>
         {(insightList.length>0&&this.props.showFour)? insightList.slice(0,4).map((item, i) =>{
          if (Object.getOwnPropertyNames(item).length !== 0){return (
           <div key={i} className="col-lg-3 col-md-3 mb-custom col-wd-2 text-center">
             <div className="card home-insight-card">
              
               <div className="card-body">
               <Link className="home-in" to={{pathname:`/resources-list/${item.type.toLowerCase().split(" ").join("-")}`,state:{ResourceName:item.type}}}>
                <img className="card-img-top" src={`${item.image_data}`} alt="Card image" />
                 <p className="card-text font-size-16">{item.type.toUpperCase()}</p>
                 <div className="card-pera font-size-12">
                 {INSIGHTTYPE.WEEKLY.toUpperCase() === item.type.toUpperCase() &&
                    <div>
                      {QOUTE.WEEKLYQ}
                      {ReactGA.pageview(item.type)}
                    </div>
                 }
                 {INSIGHTTYPE.CASEFILE.toUpperCase() === item.type.toUpperCase() &&
                    <div>
                      {QOUTE.CASEFILEQ} 
                      {ReactGA.pageview(item.type)}
                    </div>
                 }
                 {INSIGHTTYPE.SUBJECT.toUpperCase() === item.type.toUpperCase() &&
                    <div>
                      {QOUTE.SUBJECTQ}
                      {ReactGA.pageview(item.type)}
                    </div>
                 }{INSIGHTTYPE.MONTHLY.toUpperCase() === item.type.toUpperCase() &&
                  <div>
                    {QOUTE.MONTHLYQ}
                    {ReactGA.pageview(item.type)}
                  </div>
               }{INSIGHTTYPE.Quaterly.toUpperCase() === item.type.toUpperCase() &&
                <div>
                  {QOUTE.QUATERLYQ}
                  {ReactGA.pageview(item.type)}
                </div>
             }{INSIGHTTYPE.REGULATORY.toUpperCase() === item.type.toUpperCase() &&
              <div>
                {QOUTE.REGULATORYQ}
                {ReactGA.pageview(item.type)}
              </div>
           }{INSIGHTTYPE.SPOTLIGHT.toUpperCase() === item.type.toUpperCase() &&
            <div>
              {QOUTE.SPOTLIGHTQ}
              {ReactGA.pageview(item.type)}
            </div>
         }
                 </div>
                </Link>
               </div>
             </div>
           </div>)}
          }):insightList.map((item, i) =>{
            if (Object.getOwnPropertyNames(item).length !== 0){return (
             <div key={i} className="col-lg-3 col-md-3 mb-custom col-wd-2 text-center">
               <div className="card home-insight-card">
                
                 <div className="card-body">
                 <Link className="home-in" to={{pathname:`/resources-list/${item.type.toLowerCase().split(" ").join("-")}`,state:{ResourceName:item.type}}}>
                  <img className="card-img-top" src={`${item.image_data}`} alt="Card image" />
                   <p className="card-text font-size-16">{item.type.toUpperCase()}</p>
                   <div className="card-pera font-size-12">
                   {INSIGHTTYPE.WEEKLY.toUpperCase() === item.type.toUpperCase() &&
                      <div>
                        {QOUTE.WEEKLYQ}
                        {ReactGA.pageview(item.type)}
                      </div>
                   }
                   {INSIGHTTYPE.CASEFILE.toUpperCase() === item.type.toUpperCase() &&
                      <div>
                        {QOUTE.CASEFILEQ} 
                        {ReactGA.pageview(item.type)}
                      </div>
                   }
                   {INSIGHTTYPE.SUBJECT.toUpperCase() === item.type.toUpperCase() &&
                      <div>
                        {QOUTE.SUBJECTQ}
                        {ReactGA.pageview(item.type)}
                      </div>
                   }{INSIGHTTYPE.MONTHLY.toUpperCase() === item.type.toUpperCase() &&
                    <div>
                      {QOUTE.MONTHLYQ}
                      {ReactGA.pageview(item.type)}
                    </div>
                 }{INSIGHTTYPE.Quaterly.toUpperCase() === item.type.toUpperCase() &&
                  <div>
                    {QOUTE.QUATERLYQ}
                    {ReactGA.pageview(item.type)}
                  </div>
               }{INSIGHTTYPE.REGULATORY.toUpperCase() === item.type.toUpperCase() &&
                <div>
                  {QOUTE.REGULATORYQ}
                  {ReactGA.pageview(item.type)}
                </div>
             }{INSIGHTTYPE.SPOTLIGHT.toUpperCase() === item.type.toUpperCase() &&
              <div>
                {QOUTE.SPOTLIGHTQ}
                {ReactGA.pageview(item.type)}
              </div>
           }
                   </div>
                  </Link>
                 </div>
               </div>
             </div>)}
            })
        }

      { (insightList && insightList.length>0 && !this.state.requestInProgesss &&this.props.showFour) ?<></>:<>
        <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center">
          <div className="card home-insight-card">
            <div className="card-body">
            <Link className="home-in"  to={{pathname:'/resources-list/Reports'}}>
            {/* <span className="purpleBadge2">NEW</span> */}
            
              <img className="card-img-top" src={require("../../../assets/images/landing/report.png")} alt="Card image" />
              <p className="card-text font-size-16">REPORTS</p>
              <p className="card-pera font-size-12">
              Comprehensive half-yearly and annual reports, covering the AML cases, news, regulatory updates, and trends & developments, globally.
              </p>
              </Link>
            </div>
          </div>
        </div>
        {/*
         //   FOR BLOGS  <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center">
          <div className="card home-insight-card">
            <div className="card-body">
            <Link className="home-in"  to={{pathname:'/resources-list/Blogs'}}>
              <img className="card-img-top" src={require("../../../assets/images/landing/blogcard.png")} alt="Card image" />
              <p className="card-text font-size-16">BLOGS</p>
              <p className="card-pera font-size-12">
              A regular source for AML/CFT, KYC, Sanctions, Screening, etc., articles by industry experts and thought leaders in the compliance space.
              </p>
              </Link>
            </div>
          </div>
        </div> */}
        <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center">
          <div className="card home-insight-card">
            <div className="card-body">
            <Link className="home-in" to="/cpidashboard" >
              <img className="card-img-top" src={require("../../../assets/images/landing/clipboard-img.jpg")} alt="Card image" />
              <p className="card-text font-size-16">VISUALIZATION</p>
              <p className="card-pera font-size-12">
                Coverage of the lates global AML events, actions, penalties, and regulatory updates - Subscribe & stay updated
              </p>
              </Link>
            </div>
          </div>
        </div>
        </>
      }
      {
        (this.props.showFour)&&<>
        <div className='w-100 d-flex justify-content-center'>
           <a href='/resources' className='btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free mt-5'>
                 View More
           </a>
        </div>
        </>
      }
      </>}
     </div>

      </>
      
    )
  }

}


export default HomeInsights;
