import React from 'react';
import bg from '../assets/images/landing/heading.jpg';

export default function Cpidashboard() {
  return (
    <>
    {/*<div className="home-container root-conatiner">
     <CommonHeader /> */}

     {/* start page title section */}
      {/*<div className="api-box">
        <section className="wow fadeIn bg-light-gray padding-50px-tb page-title-small top-space api-header" style={{background:"url(" + bg + ")"}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-8 col-md-6 text-center text-md-left">
                        <h1 className="Sectionhead  alt-font text-extra-dark-gray mb-0">Global Corruption Impact Dashboard</h1>
                    </div>
                </div>
            </div>
        </section>
       </div> */}
      {/* end page title section */}

      {/* start post content section */}
      {/*<div className="cpi-box">

       <section>
         <div class="container">
             <div class="row">
                 <div class="col-12 blog-details-text last-paragraph-no-margin">
                     <img src="https://i0.wp.com/www.zigram.tech/wp-content/uploads/2019/07/clipboard-image-10.png" alt="" class="width-100 margin-45px-bottom text-center" />
                     <h3><strong>The impact of a country on global corruption and instability</strong></h3>
                     <h4>Introduction</h4>
                     <p>As the world becomes increasingly connected and intertwined, so do  the fortunes of various economies and regions. This has become  increasingly apparent in areas such as trade, terrorism, social  instability, technology and migration. There is growing evidence to  support that along with the positive effects of globalization, various  negative drivers also impact the way the world operates. Corruption is a  very critical part of this dynamic.</p>
                     <p>To visually represent the impact of each country, we collected data  related to the economy, remittance, terrorism, population and other  indexes, with the aim to represent a broad spectrum of data impacting or  related to corruption. Post preliminary analysis, research and  collation of a decade’s worth of data, we were able to generate a visual  story where each country is represented and their role in global  corruption is showcased.</p>
                     <iframe width="100%" style={{height:"700px"}} src="https://zigram-centralizeddashboard.azurewebsites.net/Home/EmbedReportGlobalCorrImpact" frameborder="0" allowfullscreen="true"></iframe>
                     <h4>Why Is This Important?</h4>
                     <p><span class="first-letter first-letter-block text-white-2" style={{backgroundColor:"#1b3272"}}>D</span>uring our research in various areas related to risk, anti-money  laundering, corruption and the growing threat related to forced human  migration, it became increasingly clear that many of these risk areas  are driven by global forces rather than just local ones. This is a big  change from the past, wherein the world usually saw the positive impact  of globalization and related global trends. </p>
                     <img src="https://www.zigram.tech/wp-content/uploads/2020/03/0-1.png" alt="" class="width-100 text-center margin-45px-bottom" />

                     <p>Corruption is either a driver or a result of a number of these  negative global trends and events. This is because the need, consumption  and creation of ill-gotten or undocumented cash is often as an economic  incentive for certain individuals or organizations, involved in  corruption. Often these individuals and organizations do not belong to  the location of the corruption event and may also be a trade  counter-party based somewhere else in the world. This has led  governments, security agencies, risk professionals and social  organizations to focus on understanding and tracking corruption in  various parts of the world, with the knowledge that corruption in one  region or country is no longer localized or limited to that geographic  region.</p>

                     <figure class="wp-caption alignleft"><img alt="" src="https://www.zigram.tech/wp-content/uploads/2020/03/1.png" /></figure>
                     <p>Corruption and by extension money laundering, is spread and  impacted by cross-border trade, migration, movements and economic  opportunity. What this means is that to truly understand the impact of  corruption in the world, one would need to understand the state of  corruption, money laundering, remittance, population, politics and other  trends in each country. It would also be logical to assume that the  greater the economic and overall foot-print of a country, the greater  their role in ensuring stability, peace and order in the world. </p>

                     <p>The Corruption Impact Dashboard has been developed to give business  leaders, risk professionals, regulators, law makers, banking  professionals, data enthusiasts, economists and other interested  individuals, a focused overview of the world when it comes to corruption  and the impact that each of the 180 profiled countries and territories  may have in this space. Our goal is to provide this information and  insight to those, who would like to either understand or contribute in  the global fight against corruption, make the relevant business  decisions and promote transparency.</p>
                     <h4>Data, Sources and Methodology</h4>
                     <p>To develop this dashboard and analysis, we have collected data from various validated and well regarded sources. These include:</p>
                     <ul><li>Transparency International - Corruption Perception Index</li><li>World Bank</li><li>International Monetary Fund</li><li>Basel AML Index</li><li>Institute of Economics &amp; Peace</li><li>CIA The World Factbook</li></ul>
                     <p>This data has been collated, validated and analyzed by the ZIGRAM
                         team by using our proprietary Data Asset Framework. Each data point is
                         based on a specific primary and high reputation source. To the extent
                         possible, we have not modified, updated, enhanced or made changes to the
                          core data.</p>
                     <p>To build this Data Asset, we focused on only those countries &amp;
                         territories which were part of the latest Corruption Perception Index
                         2018 and designated these as anchor profiles. Hence, the total countries
                             &amp; territories are 180 (out of estimated global 247). For these
                         anchor profiles, we then extracted data related to GDP, remittance
                         (inwards &amp; outward), population, growth rates and allied indexes. To
                             the extent possible we have collected the latest data i.e. of 2018,
                         however in certain cases, where the latest numbers have not been
                         reported, we have considered the numbers for the year 2017 or 2016. </p>
                         <p>The Basel AML Index, Corruption Perception Index (CPI) and
                             Terrorism Index are as declared in 2018. Details related to official
                             country flags and system of government are as defined in
                         <a href="https://en.wikipedia.org/wiki/List_of_countries_by_system_of_government" target="_blank" rel="noreferrer noopener">https://en.wikipedia.org/wiki/List_of_countries_by_system_of_government</a>.</p>
                         <p>Below the world map, there is a heat map which plots the Corruption
                             Perception Index (2018) of countries against their Gross Domestic
                            Product (2018). The darker the shade of the background (light grey to
                            black), the greater the assumed negative global impact of a country in
                            terms of perception of corruption. The size of the bubble of each
                            country represents the absolute change in their corruption perception
                            index (CPI) since 2012. Hence, the bubble may provide the user an
                            indication of the change in CPI, however whether the change is positive
                            or negative may only be determined from the Corruption Perception line
                            graph, placed just on top of the heat map.</p>
                            <h4>Using The Dashboard</h4>
                            <p>The dashboard has been made using Power BI and has been designed to  allow the user to interact and engage with it, in an easy to use and  intuitive manner while also getting a detailed view of various details.</p>
                            <p><strong>The dashboard overview has various elements for interaction,  search and selection, which have been marked in yellow (click on any  image to go to the dashboard - best viewed on a laptop or monitor)</strong></p>

                     <img src="https://i2.wp.com/www.zigram.tech/wp-content/uploads/2020/09/clipboard-image-11.png" alt="" class="width-100 text-center margin-45px-bottom" />
                     <p>To search for details of corruption impact related to a specific
                         country or territory, one may use the drop down or search bar just above
                          that.</p>
                     <img src="https://i2.wp.com/www.zigram.tech/wp-content/uploads/2020/09/clipboard-image-27.png" class="width-100 text-center margin-45px-bottom" />
                         <p>In addition, search can also be driven by the world map. The  intensity of red color of a country or territory indicates the  Corruption Perception Index 2018 (CPI) score for that country.</p>
                     <img src="https://i0.wp.com/www.zigram.tech/wp-content/uploads/2020/09/clipboard-image-28.png" class="width-100 text-center margin-45px-bottom" />
                         <p>Once a country is selected, the specific corruption impact details for the country or territory are showcased on the dashboard.</p>
                     <img src="https://i0.wp.com/www.zigram.tech/wp-content/uploads/2020/09/clipboard-image-29.png" class="width-100 text-center margin-45px-bottom" />

                     <p>A user may also see overview details of the country or territory by
                         either hovering over the map or the heat map. Further details or
                        selection may be viewed by clicking the heat map bubble or on the map.</p>
                        <img src="https://i1.wp.com/www.zigram.tech/wp-content/uploads/2020/09/clipboard-image-30.png" class="width-100 text-center margin-45px-bottom" />

                     <h4>Insights</h4>
                     <p>There are a number of surprising details and interesting nuggets of
                         information which become apparent once a user has engaged with the
                        dashboard. However, below are some general insights:</p>
                     <ul>
                     <li>The greatest negative impact on global corruption is exhibited by Russia &amp; Mexico</li>
                     <li>The lowest impact on global corruption is due to the mountainous Caribbean island nation of Dominica</li>
                     <li>There seems to be high correlation between high corruption  perception, low standards of Anti-Money Laundering and a high rank in  terrorism for high risk countries</li>
                     <li>Most at risk seem to be those countries, with high corruption  impact and relatively high remittance inflows in comparison to GDP –  example Nigeria, Bangladesh, Venezuela, Yemen etc.</li>
                 </ul>
                </div>
             </div>
         </div>
      </section>


      </div> */}
      {/* end blog content section */}

    {/*<CommonFooter />
   </div> */}

   <div className="home-page-conatiner">
     {/* start page title section */}
      <div className="container main-container">
       <div className="row">
          <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
           <h1>Global Correction Impact Dashboard</h1>
          </div>
       </div>
       {/* end page title section */}

      {/* api section */}
       <div className="row pt-80">
         <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
            <img className="vi-info-img" src="https://www.zigram.tech/wp-content/uploads/2019/07/clipboard-image-10.png" alt="" />
        </div>
       </div>

       <div className="row pt-50">
        <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
          <h1 className="">The impact of a country on global corruption and instability</h1>
           <div className="mtr-heading font-size-20 pb-18">Introduction</div>
           <div className="vi-info-p">
              <p>As the world becomes increasingly connected and intertwined, so do  the fortunes of various economies and regions. This has become  increasingly apparent in areas such as trade, terrorism, social  instability, technology and migration. There is growing evidence to  support that along with the positive effects of globalization, various  negative drivers also impact the way the world operates. Corruption is a  very critical part of this dynamic.</p>
              <p>To visually represent the impact of each country, we collected data  related to the economy, remittance, terrorism, population and other  indexes, with the aim to represent a broad spectrum of data impacting or  related to corruption. Post preliminary analysis, research and  collation of a decade’s worth of data, we were able to generate a visual  story where each country is represented and their role in global  corruption is showcased.</p>
              <div className="pt-18 pb-18">
                <iframe  src="https://zigram-centralizeddashboard.azurewebsites.net/Home/EmbedReportGlobalCorrImpact" frameborder="0" allowfullscreen="true"></iframe>
              </div>
              <div className="mtr-heading font-size-20 pb-18">Why Is This Important?</div>
              <p><span className="vi-info-blue-block">D</span>uring our research in various areas related to risk, anti-money  laundering, corruption and the growing threat related to forced human  migration, it became increasingly clear that many of these risk areas  are driven by global forces rather than just local ones. This is a big  change from the past, wherein the world usually saw the positive impact  of globalization and related global trends. </p>
              <div className="pt-18 pb-20">
                <img className="vi-info-img" src="https://www.zigram.tech/wp-content/uploads/2020/03/0-1.png" alt="" />
              </div>

             <p>Corruption is either a driver or a result of a number of these  negative global trends and events. This is because the need, consumption  and creation of ill-gotten or undocumented cash is often as an economic  incentive for certain individuals or organizations, involved in  corruption. Often these individuals and organizations do not belong to  the location of the corruption event and may also be a trade  counter-party based somewhere else in the world. This has led  governments, security agencies, risk professionals and social  organizations to focus on understanding and tracking corruption in  various parts of the world, with the knowledge that corruption in one  region or country is no longer localized or limited to that geographic  region.</p>

             <div className="pt-18 pb-20">
               <img className="vi-info-img" alt="" src="https://www.zigram.tech/wp-content/uploads/2020/03/1.png" />
             </div>

             <p>Corruption and by extension money laundering, is spread and  impacted by cross-border trade, migration, movements and economic  opportunity. What this means is that to truly understand the impact of  corruption in the world, one would need to understand the state of  corruption, money laundering, remittance, population, politics and other  trends in each country. It would also be logical to assume that the  greater the economic and overall foot-print of a country, the greater  their role in ensuring stability, peace and order in the world. </p>

             <p>The Corruption Impact Dashboard has been developed to give business  leaders, risk professionals, regulators, law makers, banking  professionals, data enthusiasts, economists and other interested  individuals, a focused overview of the world when it comes to corruption  and the impact that each of the 180 profiled countries and territories  may have in this space. Our goal is to provide this information and  insight to those, who would like to either understand or contribute in  the global fight against corruption, make the relevant business  decisions and promote transparency.</p>

             <div className="mtr-heading font-size-20 pb-18">Data, Sources and Methodology</div>

             <p>To develop this dashboard and analysis, we have collected data from various validated and well regarded sources. These include:</p>

             <ul>
               <li>Transparency International - Corruption Perception Index</li>
               <li>World Bank</li><li>International Monetary Fund</li>
               <li>Basel AML Index</li>
               <li>Institute of Economics &amp; Peace</li>
               <li>CIA The World Factbook</li>
             </ul>

             <p>This data has been collated, validated and analyzed by the ZIGRAM
                 team by using our proprietary Data Asset Framework. Each data point is
                 based on a specific primary and high reputation source. To the extent
                 possible, we have not modified, updated, enhanced or made changes to the
                  core data.
             </p>
             <p>To build this Data Asset, we focused on only those countries &amp;
                 territories which were part of the latest Corruption Perception Index
                 2018 and designated these as anchor profiles. Hence, the total countries
                     &amp; territories are 180 (out of estimated global 247). For these
                 anchor profiles, we then extracted data related to GDP, remittance
                 (inwards &amp; outward), population, growth rates and allied indexes. To
                     the extent possible we have collected the latest data i.e. of 2018,
                 however in certain cases, where the latest numbers have not been
                 reported, we have considered the numbers for the year 2017 or 2016.
              </p>
              <p>The Basel AML Index, Corruption Perception Index (CPI) and
                     Terrorism Index are as declared in 2018. Details related to official
                     country flags and system of government are as defined in
                 <a href="https://en.wikipedia.org/wiki/List_of_countries_by_system_of_government" target="_blank" rel="noreferrer noopener"> https://en.wikipedia.org/wiki/List_of_countries_by_system_of_government
                 </a>
              </p>
              <p>Below the world map, there is a heat map which plots the Corruption
                  Perception Index (2018) of countries against their Gross Domestic
                  Product (2018). The darker the shade of the background (light grey to
                  black), the greater the assumed negative global impact of a country in
                  terms of perception of corruption. The size of the bubble of each
                  country represents the absolute change in their corruption perception
                  index (CPI) since 2012. Hence, the bubble may provide the user an
                  indication of the change in CPI, however whether the change is positive
                  or negative may only be determined from the Corruption Perception line
                  graph, placed just on top of the heat map.
              </p>
              <div className="mtr-heading font-size-20 pb-18">Using The Dashboard</div>
              <p>The dashboard has been made using Power BI and has been designed to  allow the user to interact and engage with it, in an easy to use and  intuitive manner while also getting a detailed view of various details.</p>
              <p><strong>The dashboard overview has various elements for interaction,  search and selection, which have been marked in yellow (click on any  image to go to the dashboard - best viewed on a laptop or monitor)</strong></p>

              <div className="pt-18 pb-20">
                <img className="vi-info-img" src="https://www.zigram.tech/wp-content/uploads/2020/09/clipboard-image-11.png" alt="" />
              </div>

              <p>To search for details of corruption impact related to a specific
                 country or territory, one may use the drop down or search bar just above
                  that.
              </p>

              <div className="pt-18 pb-20">
               <img className="vi-info-img" src="https://www.zigram.tech/wp-content/uploads/2020/09/clipboard-image-27.png" />
              </div>

              <p>In addition, search can also be driven by the world map. The  intensity of red color of a country or territory indicates the  Corruption Perception Index 2018 (CPI) score for that country.</p>

              <div className="pt-18 pb-20">
               <img className="vi-info-img" src="https://www.zigram.tech/wp-content/uploads/2020/09/clipboard-image-28.png"/>
              </div>

              <p>Once a country is selected, the specific corruption impact details for the country or territory are showcased on the dashboard.</p>

              <div className="pt-18 pb-20">
                <img className="vi-info-img" src="https://www.zigram.tech/wp-content/uploads/2020/09/clipboard-image-29.png" />
              </div>

              <p>A user may also see overview details of the country or territory by
                 either hovering over the map or the heat map. Further details or
                selection may be viewed by clicking the heat map bubble or on the map.
              </p>

              <div className="pt-18 pb-20">
                <img className="vi-info-img" src="https://www.zigram.tech/wp-content/uploads/2020/09/clipboard-image-30.png" />
              </div>

              <div className="mtr-heading font-size-20 pb-18">Insights</div>

               <p>There are a number of surprising details and interesting nuggets of
                 information which become apparent once a user has engaged with the
                dashboard. However, below are some general insights:
               </p>
               <ul>
                 <li>The greatest negative impact on global corruption is exhibited by Russia &amp; Mexico</li>
                 <li>The lowest impact on global corruption is due to the mountainous Caribbean island nation of Dominica</li>
                 <li>There seems to be high correlation between high corruption  perception, low standards of Anti-Money Laundering and a high rank in  terrorism for high risk countries</li>
                 <li>Most at risk seem to be those countries, with high corruption  impact and relatively high remittance inflows in comparison to GDP –  example Nigeria, Bangladesh, Venezuela, Yemen etc.</li>
               </ul>

           </div>
        </div>
       </div>
       {/* api section */}
     </div>
  </div>
   </>
  );
}
