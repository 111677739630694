import React, { useState, useEffect } from 'react'
import axios from "axios";
import Loader from "../components/common/loader/bar-loader";
import { format } from "date-fns";
import { useParams, useHistory } from 'react-router-dom';
import Cookies from "js-cookie";
import MetaTags from 'react-meta-tags';
import { Link} from 'react-router-dom';
import ResourceDetailComponent from './Resource-Detail-Component';
 //   FOR BLOGS 
// import { BlogList } from '../apis/home-api';
// import ShowMoreText from "react-show-more-text";
// import { Markup } from 'interweave';
// import Pagination from "@material-ui/lab/Pagination";
// import bg from '../assets/images/landing/heading.jpg';
var page_size = 8;
const ResourceDetailListPage = (props) => {
  const { ResourceName } = useParams()
  const [caseList, setCaseList] = useState([]);
  const [page,setpage]=useState(1);
  const [totalpage,setTotalPAge]=useState(1);
  const [blogList ,setBlogList]=useState([]);
  const [reportList, setReportList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resourcename, setResourceName] = useState(ResourceName.split("-").join(" "));
  const [Resourcelink, setLink] = useState("");
  const [resourcehtml, setResourceHtml] = useState("");
  const [resourceImage, setResourceImage] = useState("");
  const [resourceheading, setResourceHeading] = useState("");
  const [resourceDate, setResourceDate] = useState("");
  const [resourceSnippetImage, setResourceSnippetImage]=useState("");
  const [resourceSnippetDesc, setResourceSnippetDesc]=useState("");
  const [noResource, setNoResource] = useState(false);
  const [view, setView] = useState(false);
  let history = useHistory();
  useEffect(() => {
    if (props.location.search.length !== 0) {
      setView(true);
      getResourceDetails(props.location)
    } else {
      getResourcesAPI(resourcename,page)
      let temp = resourcename.toLowerCase().split(" ").join("-")
      setLink(temp)
    }
  }, [])
  const getResourceDetails = (location) => {
    setLoading(true);
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        //Authorization: window.sessionStorage.getItem("access_token"),
      },
    });
    axiosInstance.interceptors.request.use(
      (config) => {
        const newConfig = { ...config };
        return newConfig;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    return axiosInstance
      .get(
        `${process.env.REACT_APP_NEW_BASE_URL}/cms/resources_page/${location.pathname.split("/")[2]}/${location.search.split("=")[1].split("/").join("|")}`
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.message === "No Resource Found") {
            setLoading(false);
            setNoResource(true);
          } else {
            setLoading(false);
            setResourceHtml(res.data?.data[0].description);
            setResourceDate(res.data?.data[0].date_of_action);
            setResourceImage(res.data?.data[0].image_url);
            setResourceHeading(res.data?.data[0].heading);
            // setResourceSnippetImage(res.data?.data[0].snippetimage);
            // setResourceSnippetDesc(res.data?.data[0].snippetdescription);
          }
        }
      })

  };
//   FOR BLOGS
// const getBlogList =(pageno,pagesize)=>{
//     setLoading(true)
//    BlogList(pageno,pagesize).then((res)=>{
//       setLoading(false);
//       setBlogList(res.data.data);
//       setTotalPAge(res.data.page_count <pagesize ? 1 : Math.ceil(res.data.page_count / pagesize));
//    })
//   }
//  const  handlePageChange = (event, value) => {
//   setBlogList([]);
//   setpage(value);
//   getBlogList(value,4)
//   };

  const getReportList = () => {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        //Authorization: window.sessionStorage.getItem("access_token"),
      },
    });
    axiosInstance.interceptors.request.use(
      config => {
        const newConfig = { ...config };
        return newConfig;
      },
      error => {
        Promise.reject(error);
      }
    );
    return axiosInstance
      .get(
        `${process.env.REACT_APP_NEW_BASE_URL}/cms/report?report_type=2`)
      .then(res => {

        if (res.status === 206) {
          setLoading(false);
          setReportList(res.data.data.data);
        }
      })
      .catch(err => {
        throw err;
      });

  };
  const getResourcesAPI = (name, page) => {
    setLoading(true);
    if (name === "Monthly Update" || name === "Quarterly Update" || name === "SpotLight Event" || name === "Regulatory Bulletin") page_size = 6;
    else { page_size = 8 }
    if (name !== "Visualization" && name !== "Reports" &&name!=="Blogs") {
      const axiosInstance = axios.create({
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          //Authorization: window.sessionStorage.getItem("access_token"),
        },
      });
      axiosInstance.interceptors.request.use(
        config => {
          const newConfig = { ...config };
          return newConfig;
        },
        error => {
          Promise.reject(error);
        }
      );
      return axiosInstance
        .get(
          `${process.env.REACT_APP_NEW_BASE_URL}/cms/content?type=` +
          name + '&pagination=yes&page=1&page_size=' + page_size
        )
        .then(res => {
          setLoading(false);
          // if (newTab !== this.state.currentTab) return;
          if (res && res.data && res.data.data && res.data.data.length > 0) {
            setCaseList(res.data.data);
          }
        })
        .catch(err => {
          throw err;
        });
    }
     //   FOR BLOGS 
    // else if (name === "Blogs") {
    //   getBlogList(page,4);
    // }
    else{
      getReportList();
    }
  }
  const loginRedirection = e => {
    // if (this.state.tokenMsg === "Expired or Invalid Token") {
    var date = new Date();
    date.setTime(date.getTime() + 60 * 3000);

    Cookies.set("app_id", "true", {
      expires: date,
      // domain: "localhost", // to localhost
      domain: ".amlpenalties.com",
    });
    //FOR LOCALHOST
    // window.location.replace(
    //   "http://localhost:3000/resource-list/" +
    //   resourcename.toLowerCase().split(" ").join("-")
    // );
    //EOF LOCALHOST
    // FOR APP
    //Change Url BEfore deployment
    // window.location.replace("https://devapp.amlpenalties.com/resource-list/" + resourcename.toLowerCase().split(" ").join("-"));
    window.location.replace("https://app.amlpenalties.com/resource-list/" + resourcename.toLowerCase().split(" ").join("-"));
    
  };
  return (<>
  {resourcename.toLowerCase() == "weekly bulletin" ? (
      <MetaTags>
        <meta
          id="image"
          property="og:image"
          itemprop="image"
          content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Weekly+Bulletin.PNG"
          class="img-responsive"
        />
        <meta
          id="image-url"
          property="og:image:url"
          itemprop="image"
          content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Weekly+Bulletin.PNG"
          class="img-responsive"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image:type" content="image/png" />

        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta
          property="og:description"
          content=" Weekly summary of AML related regulatory updates, cases and penalties."
        />

        <meta
          name="description"
          content=" Weekly summary of AML related regulatory updates, cases and penalties."
          data-react-helmet="true"
        />
        <meta
          id="url"
          property="og:url"
          content="https://www.amlpenalties.com/resources/weekly-bulletin"
        />
        <meta
          property="twitter:description"
          content=" Weekly summary of AML related regulatory updates, cases and penalties."
        ></meta>
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.amlpenalties.com/resources/weekly-bulletin"
        />
        <meta property="twitter:image" content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Weekly+Bulletin.PNG"></meta>
      </MetaTags>
    ) : resourcename.toLowerCase() == "regulatory bulletin" ? (
      <MetaTags>
        <meta
          id="image"
          property="og:image"
          itemprop="image"
          content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Regulation+Watch.PNG"
          class="img-responsive"
        />
        <meta
          id="image-url"
          property="og:image:url"
          itemprop="image"
          content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Regulation+Watch.PNG"
          class="img-responsive"
        />
        <meta
          name="description"
          content="Fortnight newsletter covering the latest developments, regulations and updates."
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Fortnight newsletter covering the latest developments, regulations and updates."
        />
        <meta
          id="url"
          property="og:url"
          content="https://www.amlpenalties.com/resources/regulatory-bulletin"
        />
        <meta
          property="twitter:description"
          content="Fortnight newsletter covering the latest developments, regulations and updates."
        ></meta>
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.amlpenalties.com/regulatory-bulletin"
        />
        <meta property="twitter:image" content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Regulation+Watch.PNG"></meta>
      </MetaTags>
    ) : resourcename.toLowerCase() == "case files" ? (
      <MetaTags>
        <meta
          id="image"
          property="og:image"
          itemprop="image"
          content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Case+Files.PNG"
          class="img-responsive"
        />
        <meta
          id="image-url"
          property="og:image:url"
          itemprop="image"
          content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Case+Files.PNG"
          class="img-responsive"
        />
        <meta
          name="description"
          content=" Important case details, modus operandi and sentencing of known public figures implicated under AML laws globally."
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Important case details, modus operandi and sentencing of known public figures implicated under AML laws globally."
        />
        <meta
          id="url"
          property="og:url"
          content="https://www.amlpenalties.com/resources/case-files"
        />
        <meta
          property="twitter:description"
          content="Important case details, modus operandi and sentencing of known public figures implicated under AML laws globally."
        ></meta>
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.amlpenalties.com/resources/case-files"
        />
        <meta property="twitter:image" content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Case+Files.PNG"></meta>
      </MetaTags>
    ) : resourcename.toLowerCase() == "the subject matter" ? (
      <MetaTags>
        <meta
          id="image"
          property="og:image"
          itemprop="image"
          content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Subject+Matter.PNG"
          class="img-responsive"
        />
        <meta
          id="image-url"
          property="og:image:url"
          itemprop="image"
          content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Subject+Matter.PNG"
          class="img-responsive"
        />
        <meta
          name="description"
          content="  Introduction and details about the latest Anti Money Laundering news and updated globally. Essential for all Anti Money Laundering subject matter experts."
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Introduction and details about the latest Anti Money Laundering news and updated globally. Essential for all Anti Money Laundering subject matter experts"
        />
        <meta
          id="url"
          property="og:url"
          content="https://www.amlpenalties.com/resources/the-subject-matter"
        />
        <meta
          property="twitter:description"
          content="Introduction and details about the latest Anti Money Laundering news and updated globally. Essential for all Anti Money Laundering subject matter experts"
        ></meta>
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.amlpenalties.com/resources/the-subject-matter"
        />
        <meta property="twitter:image" content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Subject+Matter.PNG"></meta>
      </MetaTags>
    ) : resourcename.toLowerCase() == "spotlight event" ? (
      <MetaTags>
        <meta
          id="image"
          property="og:image"
          itemprop="image"
          content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Spotlight+Event.PNG"
          class="img-responsive"
        />
        <meta
          id="image-url"
          property="og:image:url"
          itemprop="image"
          content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Spotlight+Event.PNG"
          class="img-responsive"
        />
        <meta
          name="description"
          content=" Major money laundering event that highlights substantial breaches and non compliance with AML/CFT regulations"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content=" Major money laundering event that highlights substantial breaches and non compliance with AML/CFT regulations"
        />
        <meta
          id="url"
          property="og:url"
          content="https://www.amlpenalties.com/resources/spotlight-event"
        />
        <meta
          property="twitter:description"
          content=" Major money laundering event that highlights substantial breaches and non compliance with AML/CFT regulations"
        ></meta>
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.amlpenalties.com/resources/spotlight-event"
        />
        <meta property="twitter:image" content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Spotlight+Event.PNG"></meta>
      </MetaTags>
    ) : resourcename.toLowerCase() == "monthly update" ? (
      <MetaTags>
        <meta
          id="image"
          property="og:image"
          itemprop="image"
          content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Monthly+Update.PNG"
          class="img-responsive"
        />
        <meta
          id="image-url"
          property="og:image:url"
          itemprop="image"
          content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Monthly+Update.PNG"
          class="img-responsive"
        />
        <meta
          name="description"
          content="Monthly Infographic of AML related regulatory updates, cases, and penalties"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Monthly Infographic of AML related regulatory updates, cases, and penalties"
        />
        <meta
          id="url"
          property="og:url"
          content="https://www.amlpenalties.com/resources/other-updates"
        />
        <meta
          property="twitter:description"
          content="Monthly  Infographic of AML related regulatory updates, cases, and penalties"
        ></meta>
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.amlpenalties.com/resources/other-updates"
        />
        <meta property="twitter:image" content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Monthly+Update.PNG"></meta>
      </MetaTags>
    ) : resourcename.toLowerCase() == "quarterly update" ?(
      <MetaTags>
                   <meta
          id="image"
          property="og:image"
          itemprop="image"
          content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Quarterly+Update.PNG"
          class="img-responsive"
        />
        <meta
          id="image-url"
          property="og:image:url"
          itemprop="image"
          content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Quarterly+Update.PNG"
          class="img-responsive"
        />
        <meta
          name="description"
          content="Quarterly Infographic of AML related regulatory updates, cases, and penalties"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Quarterly Infographic of AML related regulatory updates, cases, and penalties"
        />
        <meta
          id="url"
          property="og:url"
          content="https://www.amlpenalties.com/resources/other-updates"
        />
        <meta
          property="twitter:description"
          content="Quarterly Infographic of AML related regulatory updates, cases, and penalties"
        ></meta>
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.amlpenalties.com/resources/other-updates"
        />
        <meta property="twitter:image" content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/Quarterly+Update.PNG"></meta>
      </MetaTags>
    ):(<MetaTags></MetaTags>)}
    {props.location.search.length === 0 ?
      <div className="">
        <div className=' main-container1   ' >

          <div className="row ml-5">
            <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 pt-20">
              <h4 className='resourceSubHeading'>{resourcename.toUpperCase()}</h4>
            </div>
          </div>
          {loading ? <div className='row  pt-110 justify-content-center'><div className='col-3 text-center'><Loader loading /></div></div> : <>
          <div className='container'>
            <div className="row pt-80 w-100 ">
              {(caseList.length > 0 && resourcename !== 'Reports' && resourcename !== 'Blogs' ?
                caseList.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="col-lg-3 col-md-3 col-wd-2 text-center mb-3"
                    >
                    { (resourcename.toLowerCase()!=="weekly bulletin"&&resourcename.toLowerCase()!=="regulatory bulletin") ?
                    <a className="home-in" href={`/resources-list/${Resourcelink}?name=${item.heading.split(" ").join("-")}`}>
                        <div className="card lg-card in-cursor">
                          <div>
                            <img
                              loading="lazy"
                              className="card-img-top"
                              src={`${item.image_data}`}
                              alt="Card image"
                            />
                          </div>
                          <div className="card-body">
                            <p className="card-text font-size-16">
                              {item.heading.length > 35
                                ? item.heading.substr(0, 35) + "..."
                                : item.heading}
                            </p>
                            <p className="card-pera font-size-12">
                              <b>Date of Release </b>{" "}
                              {format(new Date(item.date_of_action), "d MMM yyyy")}
                            </p>
                          </div>
                        </div>
                      </a>:
                      <a className='home-in' href={ item.external_link
                        ? item.external_link
                        : "https://mailchi.mp/aml.zone/aml-zone-weekly-bulletin_24_august"} target="_blank">
                           <div className="card lg-card in-cursor">
                          <div>
                            <img
                              loading="lazy"
                              className="card-img-top"
                              src={`${item.image_data}`}
                              alt="Card image"
                            />
                          </div>
                          <div className="card-body">
                            <p className="card-text font-size-16">
                              {item.heading.length > 35
                                ? item.heading.substr(0, 35) + "..."
                                : item.heading}
                            </p>
                            <p className="card-pera font-size-12">
                              <b>Date of Release </b>{" "}
                              {format(new Date(item.date_of_action), "d MMM yyyy")}
                            </p>
                          </div>
                        </div>
                      </a>
                }
                    </div>
                  );
                }) : resourcename == 'Reports'?
                 <div>
                  {reportList.length > 0 &&
                    reportList.map((report) => (
                      <div
                        className="col-lg-12 col-md-12 mb-4 col-wd-2 mb-3"
                        key={report.ReportId}
                      >
                        <div className="card reportCard">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-left">
                                <div>
                                  <img
                                    className="img-fluid"
                                    src={`data:image/png;base64,${report.ReportSnippetData}`}
                                    alt={report.ReportName}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-9 col-md-9 mb-4 mb-md-0 col-wd-2 pl-45">
                                <h4 className="vi-card-bold font-size-20">
                                  {report.ReportName}
                                </h4>
                                <p className="vi-card-pera font-size-12">
                                  {report.ReportDescription}
                                </p>
                                {   report.ReportName==='AML Penalties Annual Report 2021 Teaser'
                                      ? <Link className="text-primary" to={{
                                        pathname:'/AML-Penalties-Annual-Report-2021-Teaser',
                                        state:{
                                          id:report.ReportId
                                        } 
                                      }}> Access Full Teaser</Link>
                                      : <a
                                  className="text-primary"
                                  href={
                                 `https://app.amlpenalties.com/report/${report.ReportName}`
                                  }
                                >
                                  Access Full Report
                                </a>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>:<> 
                   {/*
                    //   FOR BLOGS  { blogList.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="col-lg-3 col-md-3 col-wd-2 text-center mb-4"
                    >
                    <a className="home-in" href={`/blog?name=${item.heading.split(" ").join("-")}`}>
                        <div className="card lg-card in-cursor blog-list-card">
                          <div>
                            <img
                              loading="lazy"
                              className="card-img-top blog-img-card-top"
                              src={item.Heading_Image!==''?item.Heading_Image:bg}
                              alt="Card image"
                            />
                          </div>
                          <div className="card-body">
                            <p className="card-text font-size-16">
                          {item.heading}
                            </p>
                            <p className="card-pera font-size-12">
                              <b>Date of Release </b>{" "}
                              {format(new Date(item.upload_date), "d MMMM yyyy")}
                            </p>
                            <div className="card-pera1 font-size-12 mt-4">
                            <ShowMoreText
                              lines={4}
                              more="Show more"
                              less="Show less"
                              anchorClass="font-size-14 hovera mx-2"
                              expanded={false}
                              truncatedEndingComponent={"... "}
                            >
                             { <Markup content={item.description} />}
                            </ShowMoreText>
                          </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                }) }
                 */}
                </>)}
              {(resourcename !== 'Reports'&&resourcename!=='Blogs') && <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                <div className="lr-btn-block">
                  <button
                    type="button"
                    className="btn btn-primary sm-primary lr-btn"
                    onClick={(e) => loginRedirection()}
                  >
                    {!Cookies.get("remove_id")
                      ? "LOGIN TO SEE MORE"
                      : "CLICK TO SEE MORE"}
                  </button>
                </div>
              </div>}
              </div>
              {/* <div className="row mb-4 mt-2 justify-content-center">
            {   
              (!loading  && resourcename == 'Blogs') ?<Pagination
              count={totalpage}
              page={page}
              showFirstButton={true}
              showLastButton={true}
              onChange={handlePageChange}
            />:""
            }
            
          </div> */}
            </div>
          </>}
        </div>


      </div> :
      <ResourceDetailComponent html={resourcehtml} heading={resourceheading} image={resourceImage} date={resourceDate} loading={loading} noResource={noResource} type={ResourceName} />
    }</>
  )
}

export default ResourceDetailListPage