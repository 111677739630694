import React from 'react';
import { createChatBotMessage  ,createCustomMessage } from 'react-chatbot-kit';
import CustomMessage from './customMessage';
const config = {
    initialMessages: [createChatBotMessage(`Welcome to AML Penalties. Please submit your query via the form below, and we'll respond promptly. Thank you for reaching out!`),
    createCustomMessage('Test', 'custom')],
    botName:'Auto bot',
    customStyles: {
        botMessageBox: {
          backgroundColor:  "#0e096c",
        },
        chatButton: {
          backgroundColor: '#8b9dc3',
        },
      },
      customMessages: {
        custom: (props) => <CustomMessage {...props} />,
      },
      customComponents:{
        botAvatar:(props)=><div className='bot-avatar'><p>A</p></div>
      }
  };

export default config;