import React, { PureComponent } from 'react';
import bg from '../assets/images/landing/heading.jpg';
import { Accordion,Card, Button } from "react-bootstrap";



const exmResponse1 = {
    "data": [
      {
        "CaseSummary": "Oluwaseun John Ogundele was penalized in United States of America on June 16, 2020.\r\nOgundele had been knowingly concealed the proceeds of a romance scam operation by moving money between and among multiple bank accounts that were opened under various aliases using fake passports and other fraudulent identification documents to obscure the source of the funds and the identities of the co-conspirators in between 2015 and November 2019.\r\nOn June 15, 2020, Ogundele pleaded guilty to conspiracy to commit money laundering.\r\nImportant Dates:\r\nScheme Executed- 2015 and November 2019.\r\nGuilty Plea- June 15, 2020.",
        "CountryName": "United States of America",
        "EventDate": "2020-06-15",
        "EventName": "Oluwaseun John Ogundele penalized in United States of America on Jun 2020",
        "EventUID": "EV007543",
        "Link": [
          {
            "Link": "https://www.justice.gov/usao-ndok/pr/former-oklahoman-pleads-guilty-money-laundering-his-role-nigerian-fraud-scheme"
          }
        ],
        "related_entity": [],
        "related_individual": [
          {
            "CountryName": "United States of America",
            "Gender": null,
            "IndividualIndustry": "",
            "IndividualName": "Oluwaseun John Ogundele",
            "IndividualUID": "IN0010707"
          }
        ]
      },
      {
        "CaseSummary": "Autumn St. John was penalized in the United States of America on February 19, 2020.  The defendant was accused of drug trafficking.  On November 29, 2018, a cooperating individual, at the direction of the Nashua Police Department, purchased fentanyl from St. John.  Later, on June 3, 2019, a Nashua detective on patrol watched St. John make a hand-to-hand sale of fentanyl.  After being arrested, St. John admitted that she had just sold drugs to a customer.  On February 19, 2020, St. John pleaded guilty to the charges. Important Dates: Pleaded Guilty- February 19, 2020.",
        "CountryName": "United States of America",
        "EventDate": "2020-02-19",
        "EventName": "Autumn St. John penalized in United States of America in Feb, 2020",
        "EventUID": "EV005900",
        "Link": [
          {
            "Link": "https://www.justice.gov/usao-nh/pr/nashua-woman-pleads-guilty-drug-trafficking"
          }
        ],
        "related_entity": [],
        "related_individual": [
          {
            "CountryName": "United States of America",
            "Gender": null,
            "IndividualIndustry": null,
            "IndividualName": "Autumn St. John",
            "IndividualUID": "IN0008181"
          }
        ]
      }
    ],
    "message": "Event Search Successful"
  }


  const exmResponse2 =  {
       "count": 2,
       "data": [
         {
           "CountryName": "United States of America",
           "Gender": "Male",
           "IndividualIndustry": null,
           "IndividualName": "John Paul Gutschlag",
           "IndividualUID": "IN0000034",
           "ratio": 100,
           "related_entity": [],
           "related_event": [
             {
               "CaseSummary": "John Paul Gutschlag, Owner of GM-Southwest Inc were penalized in Virginia on May 22, 2014.\nThey were penalized for charges of racketeering and money laundering.\nOn May 2013, the US District Court for the Western District of Virginia, Abingdon imposed a fine of USD 600,000, a restitution of USD 600,000\n and an imprisonment of 18 months to Paul for money laundering and violation of the Racketeering Act while a fine of USD 600,000, a restitution of USD 250,000 to Lane for his association in the fraud and filing a false tax return.\nImportant Dates: Penalty Imposed-2013.\n",
               "CountryName": "United States of America",
               "EventDate": "2014-05-22",
               "EventName": "GM-Southwest, John Paul Gutschlag, James Lane, Carolyn Beck penalized in United States of America in May, 2014",
               "EventUID": "EV000224"
             }
           ]
         },
         {
           "CountryName": "United States of America",
           "Gender": "Male",
           "IndividualIndustry": null,
           "IndividualName": "John B. Hyde",
           "IndividualUID": "IN0000669",
           "ratio": 100,
           "related_entity": [],
           "related_event": [
             {
               "CaseSummary": "John B. Hyde was penalized in the United States of America dated June 29, 2005.    \nHyde was penalized for his role in a scheme in which he defrauded thousands of people throughout the United States. The victims purchased health insurance plans from Mr. Hyde's company, only to discover after illnesses or accidents that their health insurance was essentially worthless and that the premiums they paid had not been used as he had promised.\nOn June 29, 2005, the U.S. District Court of California imposed imprisonment of 41 months and restitution of USD 1,300,000 on Hyde for the aforesaid.\nImportant Dates: Penalty Imposed- June 29, 2005.   ",
               "CountryName": "United States of America",
               "EventDate": "2005-06-29",
               "EventName": "John B. Hyde penalized in United States of America in Jun, 2005",
               "EventUID": "EV001283"
             }
           ]
         }
       ],
       "message": "Individual Search Successful"
     }

  const exmResponse3 = {
      "count": 2,
      "data": [
        {
          "CountryName": "Argentina",
          "EntityIndustry": "BFSI",
          "EntityName": "HSBC Bank Argentina",
          "EntitySector": "Banking",
          "EntityUID": "EN0000218",
          "ratio": 100,
          "related_event": [
            {
              "CaseSummary": "HSBC Bank Argentina S.A. was penalized in Argentina dated December 14, 2012.\nThey were penalized for failing to report suspicious company transactions between 2005 to 2006. The Bank failed to disclose a three million dollar transaction by a bread bakers' association.\nOn December 2015, the Anti-Money Laundering Office (AMLO) imposed a fine of ARS 30,000,000 on the bank for the aforesaid.\nImportant Dates: Penalty Imposed- 2015.",
              "CountryName": "Argentina",
              "EventDate": "2012-12-14",
              "EventName": "HSBC Bank Argentina penalized in Argentina in Dec, 2012",
              "EventUID": "EV000246"
            }
          ],
          "related_individual": []
        },
        {
          "CountryName": "Hong Kong",
          "EntityIndustry": "",
          "EntityName": "HSBC Global Asset Management (Hong Kong) Limited (HGAML)",
          "EntitySector": "",
          "EntityUID": "EN0001729",
          "ratio": 100,
          "related_event": [
            {
              "CaseSummary": "HSBC Investment Funds (Hong Kong) Limited (HIFL) and HSBC Global Asset Management (Hong Kong) Limited (HGAML) were penalized in Hong Kong dated April 07, 2020.\nHIFL and HGAML managed or advised 53 funds in 2010 to 2016 maintained cash deposits with connected entities, namely, The Hong Kong and Shanghai Banking Corporation Limited and its affiliates. \nThe funds’ cash deposits were placed in interest-bearing accounts of the connected entities but mostly did not receive any interest. \nIn January 2015, HIFL and HGAML had no procedures in place to ensure that the funds’ cash deposited with their connected entities received interest at a rate not lower than the prevailing commercial rate.\nOn April 07, 2020, The Securities and Futures Commission, Hong Kong imposed a fine of HKD 433,257 million on HSBC Investment Funds (Hong Kong) Limited and HSBC Global Asset Management (Hong Kong) Limited.\nImportant Dates\nScheme Period - 2010 to 2016\nPenalty - April 07, 2020",
              "CountryName": "Hong Kong",
              "EventDate": "2020-04-07",
              "EventName": "HSBC Investment Funds (Hong Kong) Limited (HIFL) and HSBC Global Asset Management (Hong Kong) Limited (HGAML) penalized in Hong Kong in Apr 2020",
              "EventUID": "EV006785"
            }
          ],
          "related_individual": []
        }
      ],
      "message": "Entity Search Successful"
    }

class ApiDocs extends PureComponent {

  constructor() {
    super();
    this.state = {
      navUrl:'#download-section'
    };
  }

  componentDidMount() {
   window.scrollTo(0, 0);
    // $('.nav').affix();
     // $('.nav').affix({offset: {top: 150} });
 }

   handleShow = (value) => {
      this.setState({
         navUrl:value
      });
   }



render() {
  return (
    <div className="home-page-conatiner">
      {/* start page title section */}
       <div className="container main-container">
        <div className="row">
           <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
            <h1>API DOCUMENTATION</h1>
           </div>
           <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
              <span className="font-color-grey font-size-14">
                AML Penalties has an API-style output designed for developer documentation. It enables users to perform searches on the database. This document provides a brief API overview, authentication, workflow, prerequisite steps, response and error information, endpoints definition, concepts table, search and tags, user registration, and API key generation.
              </span>
            </div>
        </div>
        {/* end page title section */}

        {/* start post content section */}
        <div className="row pt-18 pb-18">
          <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
             <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2">
            <nav className="sidebar">
              <a  href="#download-section" onClick={() =>this.handleShow("#download-section")} className={this.state.navUrl==='#download-section'?'nav-link scrollto activeMenu':'nav-link scrollto'}>
                 API Overview
              </a>

              <a  href="#installation-section" onClick={() =>this.handleShow("#installation-section")} className={this.state.navUrl==='#installation-section'?'nav-link scrollto activeMenu':'nav-link scrollto'}>
                 Authentication
              </a>

              <a  href="#code-section" onClick={() =>this.handleShow("#code-section")} className={this.state.navUrl==='#code-section'?'nav-link scrollto activeMenu':'nav-link scrollto'}>
                  Responses and Errors
              </a>

              <a  href="#callouts-section" onClick={() =>this.handleShow("#callouts-section")} className={this.state.navUrl==='#callouts-section'?'nav-link scrollto activeMenu':'nav-link scrollto'}>
                 End Points and Definitions
              </a>

              <a  href="#tables-section" onClick={() =>this.handleShow("#tables-section")} className={this.state.navUrl==='#tables-section'?'nav-link scrollto activeMenu':'nav-link scrollto'}>
                 Concepts Table
              </a>
              <a  href="#buttons-section" onClick={() =>this.handleShow("#buttons-section")} className={this.state.navUrl==='#buttons-section'?'nav-link scrollto activeMenu':'nav-link scrollto'}>
                 Searches and Tags
              </a>

              <a href="#video-section" onClick={() =>this.handleShow("#video-section")} className={this.state.navUrl==='#video-section'?'nav-link scrollto activeMenu':'nav-link scrollto'}>
                 User Registeration
              </a>

              <a href="#icons-section" onClick={() =>this.handleShow("#icons-section")} className={this.state.navUrl==='#icons-section'?'nav-link scrollto activeMenu':'nav-link scrollto'}>
                 API Key Generation
              </a>
            </nav>
          </div>

          <div className="col-lg-9 col-md-9 mb-4 mb-md-0 col-wd-2">

            <section className="section-pb-50" id="download-section">
               <div className="vi-info-p" >
                 <div className="mtr-heading font-size-25 pb-18">API Overview</div>
                 <p>Access real-time risk data and find the insight if you&rsquo;re looking for to make AML risk decisions, faster.</p>
                 <p>Our unique API solution has been designed to provide you with all the functionality you need to integrate our data and services into your anti-money laundering processes &amp; systems.</p>
                 <p>The API enables you to integrate your systems with our services to automate many of the functions available through the web user interface.</p>
                 <p>Our API follows the REST convention. JSON data is accepted and returned.</p>
                 <p>All requests should use the following root API URL:</p>
                 <p>Please <a href="/contact-us" style={{textDecoration: "underline"}}>contact us</a> for API related information.</p>
                 <p>&nbsp;</p>
                 <p>API displayed in the documentation is for representation purpose.</p>
               </div>
            </section>

            <section className="section-pb-50" id="installation-section">
               <div className="vi-info-p" >
               <div className="mtr-heading font-size-25 pb-18">Authentication</div>
                 <p>The AML Penalties API uses API keys to authenticate requests. These API keys can be generated within the web platform and must be supplied with each request.</p>
                 <p>The API keys will only be used to perform a variety of actions against the API; GET requests do not affect data in your account, users will not have the right to create, alter and edit the reference data</p>
                 <p>Standards to follow:</p>
                 <ul>
                    <li>Keep the keys secure - do not share API keys or give them to parties outside your organisation</li>
                    <li>Rotate your API keys on a frequent basis</li>
                    <li>Do not store your API keys in publicly-accessible locations</li>
                 </ul>
                 <p>When making requests to the AML Penalties API there are three methods for providing your API key:</p>
                 <ol>
                    <li>Query string parameter</li>
                    <li>Json Body</li>
                    <li>Form Data</li>
                 </ol>
               </div>
          </section>

          <section className="section-pb-50" id="code-section">
              <div className="vi-info-p" >
                 <div className="mtr-heading font-size-25 pb-18">Responses and Errors</div>
                 <p>The success or failure of an API request is indicated by standard HTTP status codes.</p>
                 <p>Requests that are successfully processed have HTTP status code 200, whereas those which are unsuccessful have a non-200 HTTP status code</p>
                 <div className="note-tag">
                    <h4 className="callout-title">Please Note:</h4>
                    <p> With a GET request, it is easy to tell if the request is successful because you get back the expected response.</p>
                 </div>
              </div>
            </section>

            <section className="section-pb-50" id="callouts-sectionn">
               <div className="vi-info-p">
                  <div className="mtr-heading font-size-25 pb-18">API Endpoints</div>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">ENDPOINT</th>
                        <th scope="col">DESCRIPTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>POST /register</td>
                        <td>Register user to the API</td>
                      </tr>
                      <tr>
                        <td>POST /regenarateapikey</td>
                        <td>Regenerate key for existing user</td>
                      </tr>
                      <tr>
                        <td>POST /search</td>
                        <td>Search the AML database</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="note-tag">
                     <div className="mtr-heading font-size-16 pt-18">Please Note:</div>
                     <p> With a GET request, it is easy to tell if the request is successful because you get back the expected response.</p>
                  </div>
               </div>
             </section>

            <section className="section-pb-50" id="tables-section">
               <div className="vi-info-p" >
                 <div className="mtr-heading font-size-25 pb-18">Concepts Table</div>
                 <table className="table table-hover">
                   <thead>
                     <tr>
                       <th scope="col">Datafield Name </th>
                       <th scope="col">Data Type</th>
                       <th scope="col">Description</th>
                     </tr>
                   </thead>
                   <tbody>
                     <tr>
                       <td>Event UID</td>
                       <td>Alphanumeric</td>
                       <td>Unique ID for Every Event Created in the Dataset</td>
                     </tr>
                     <tr>
                       <td>Event Name</td>
                       <td>Alphanumeric</td>
                       <td>Name of the Event as per Standards</td>
                     </tr>
                     <tr>
                       <td>Event Date</td>
                       <td>Date</td>
                       <td>Date of earliest action taken by a regulator (conviction or sentencing)</td>
                     </tr>
                     <tr>
                       <td>Important Dates</td>
                       <td>Date</td>
                       <td>Dates of Important milestones in an event</td>
                     </tr>
                     <tr>
                       <td>Total Penalty</td>
                       <td>Numeric</td>
                       <td>Sum of all monetory penaltieson all implicated individuals and entities.</td>
                     </tr>
                     <tr>
                       <td>Independent Penalties</td>
                       <td>Numeric</td>
                       <td>Independent penalties on every implicated individual and entity</td>
                     </tr>
                     <tr>
                        <td>Total Imprisonment</td>
                        <td>Numeric</td>
                        <td>Sum of all imprisonments on all implicated individuals </td>
                     </tr>
                     <tr>
                        <td>Independent Imprisonments</td>
                        <td>Numeric</td>
                        <td>Independent imprisonment on every implicated individual </td>
                     </tr>
                     <tr>
                        <td>Country Event</td>
                        <td>Character</td>
                        <td>Country in which the event took place</td>
                     </tr>
                     <tr>
                        <td>Countries Impacted</td>
                        <td>Character</td>
                        <td>Countries impacted by the event</td>
                     </tr>
                     <tr>
                        <td>Event Summary</td>
                        <td>Free Text</td>
                        <td>A brief highlight of the event covering all important facts and imformation related to the event</td>
                     </tr>
                     <tr>
                        <td>Regulating Agency</td>
                        <td>Free Text</td>
                        <td>Name of the Regulating Agency that Imposed penalty on the entity and/or individual</td>
                     </tr>
                     <tr>
                        <td>Regulating Agency UID</td>
                        <td>Alphanumeric</td>
                        <td>Unique ID or Every Regulator Created in the Dataset</td>
                     </tr>
                     <tr>
                        <td>Country of Regulator</td>
                        <td>Character</td>
                        <td>Country in which the Regulator is registered or headquatered</td>
                     </tr>
                     <tr>
                        <td>Reasoning for Penalty</td>
                        <td>Free Text</td>
                        <td>The reasoning for which the entity and/or individual had been penalized</td>
                     </tr>
                     <tr>
                        <td>Reasoning Category</td>
                        <td>Free Text</td>
                        <td>The category to which the reasoning for penalty belongs to </td>
                     </tr>
                     <tr>
                        <td>Individuals Involved</td>
                        <td>Alphanumeric</td>
                        <td>Names of all individuals that were implicated in the event</td>
                     </tr>
                     <tr>
                        <td>Individual UID</td>
                        <td>Alphanumeric</td>
                        <td>Unique ID for Every Individual Created in the Dataset</td>
                     </tr>
                     <tr>
                        <td>Entities Involved</td>
                        <td>Alphanumeric</td>
                        <td>Names of all entities that were implicated in the event</td>
                     </tr>
                     <tr>
                        <td>Entity UID</td>
                        <td>Alphanumeric</td>
                        <td>Unique ID for Every Entity Created in the Dataset</td>
                     </tr>
                     <tr>
                        <td>Sector of Entity</td>
                        <td>Character</td>
                        <td>Pre-Defined Industry in which the Entity Operates</td>
                     </tr>
                     <tr>
                        <td>Sources</td>
                        <td>URL</td>
                        <td>Links to the sources of information pertaining to the event</td>
                     </tr>
                   </tbody>
                 </table>
               </div>
             </section>

             <section className="section-pb-50" id="buttons-section">
               <div className="vi-info-p" >
                <div className="mtr-heading font-size-25 pb-18">Searches & Tag</div>
                <div>Creating Searches</div>
                <ul>
                   <li>Create a new search by POSTing search terms, parameters and filters. By default creating a search will pull the first 50 results</li>
                </ul>
                <div className="pt-18 pb-20">
                  <img className="vi-info-img" src={require("../assets/images/landing/apo.png")} data-no-retina="" />
                </div>
               </div>

               <div className="pt-18 accordion-tag">
                <Accordion>
                <Card>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    <a href="javascript:void(0)" className="headcol">
                      <div>
                       <span className="block-title">Keyword Search</span>
                       <i className="fa fa-angle-down"></i>
                      </div>
                    </a>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                      <div className="section-block font-size-12">
                         <p><code><a href="https://dummyapi.amlpenalties.com/dummy/dummysearch">https://dummyapi.amlpenalties.com/dummy/dummysearch</a></code></p>
                         <p>When search is performed with type "keywords", the API performs a free-text search on the database to find any AML events that have the given keywords either in their name or their case summary.</p>
                         <p>In addition to the information related to the retrieved events, the API also shows information about the individuals and entities related to these.</p>
                         <br/> <br/>
                         <table className="table table-hover">
                            <thead>
                               <tr>
                                  <th scope="col">FIELD TYPE</th>
                                  <th scope="col">TYPE</th>
                                  <th scope="col">DESCRIPTION</th>
                               </tr>
                            </thead>
                            <tbody>
                               <tr>
                                  <td>search</td>
                                  <td>String</td>
                                  <td>The keyword to be searched</td>
                               </tr>
                               <tr>
                                  <td>key</td>
                                  <td>String</td>
                                  <td>The unique API key</td>
                               </tr>
                               <tr>
                                  <td>type</td>
                                  <td>String</td>
                                  <td>"keywords" for event search</td>
                               </tr>
                               <tr>
                                  <td>country<em>(optional)</em></td>
                                  <td>String</td>
                                  <td>The country for which search needs to be
                                     done
                                  </td>
                               </tr>
                               <tr>
                                  <td>fromdate<em>(optional)</em></td>
                                  <td>String</td>
                                  <td>The starting date for the search</td>
                               </tr>
                               <tr>
                                  <td>todate<em>(optional)</em></td>
                                  <td>String</td>
                                  <td>The ending date for the search</td>
                               </tr>
                               <tr>
                               <td>result_set <em>(optional)</em></td>
                               <td>String</td>
                               <td>The number of results to retrieve(defaults to 25)</td>
                               </tr>
                            </tbody>
                         </table>
                         <br/> <br/><div className="mtr-heading font-size-14">Example Response:</div><br/><br/>
                          <pre>
                            <code>
                           {JSON.stringify(exmResponse1, null, 2) }
                           </code>
                          </pre>
                         <br/>
                         <div className="mtr-heading font-size-14">Example Request</div>
                         <p>BODY <span>raw</span></p>
                         <pre>
                            <code>
                             {JSON.stringify({
                                 "search": "John",
                                 "key": "3a3a537390084992bef508cc0cca3d29",
                                 "type": "keywords"
                             }, null, 2) }
                            </code>
                        </pre>
                      </div>

                      </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    <a href="javascript:void(0)" className="headcol">
                       <span className="block-title">Individual Search</span>
                       <i className="fa fa-angle-down"></i>
                    </a>
                    </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                    <div id="step2" className="section-block">
                       <p><code><a href="https://dummyapi.amlpenalties.com/dummy/dummysearch">https://dummyapi.amlpenalties.com/dummy/dummysearch</a></code></p>
                       <p>When search is performed with type "individual", the API performs a fuzzy search on the database to find any individuals who have names similar to the string provided in "search".</p>
                       <p>In addition to the information related to the retrieved individuals, the API also shows information about the events and entities related to them.</p>
                       <br/> <br/>
                       <table className="table table-hover">
                          <thead>
                             <tr>
                                <th scope="col">FIELD TYPE</th>
                                <th scope="col">TYPE</th>
                                <th scope="col">DESCRIPTION</th>
                             </tr>
                          </thead>
                          <tbody>
                             <tr>
                                <td>search</td>
                                <td>String</td>
                                <td>The keyword to be searched</td>
                             </tr>
                             <tr>
                                <td>key</td>
                                <td>String</td>
                                <td>The unique API key</td>
                             </tr>
                             <tr>
                                <td>type</td>
                                <td>String</td>
                                <td>"individual" for individual search</td>
                             </tr>
                             <tr>
                                <td>country(optional)</td>
                                <td>String</td>
                                <td>The country for which search needs to be done</td>
                             </tr>
                             <tr>
                                <td>search_ratio(optional)</td>
                                <td>String</td>
                                <td>The fuzziness level for the search(defaults to 87.5)</td>
                             </tr>
                             <tr>
                                <td>result_set(optional)</td>
                                <td>String</td>
                                <td>The number of results to retrieve
                                   (defaults to 25)
                                </td>
                             </tr>
                          </tbody>
                       </table>
                       <br/>
                       <div className="mtr-heading font-size-14">Example Response:</div>

                        <code>
                        <pre>
                          {JSON.stringify(exmResponse2, null, 2) }
                         </pre>
                          </code>

                       <br/> <br/>
                       <div className="mtr-heading font-size-14">Example Request</div>
                       <p>BODY raw</p>

                       <pre>
                         <code>
                         {JSON.stringify({
                             "search": "hsbc",
                             "key": "3a3a537390084992bef508cc0cca3d29",
                             "type": "Individual"
                         }, null, 2) }
                         </code>
                       </pre>

                    </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    <a href="javascript:void(0)" className="headcol">
                       <span className="block-title">Entity search</span>
                       <i className="fa fa-angle-down"></i>
                    </a>
                    </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                    <div id="step3" className="section-block">
                       <p><code><a href="https://dummyapi.amlpenalties.com/dummy/dummysearch">https://dummyapi.amlpenalties.com/dummy/dummysearch</a></code></p>
                       <p>When search is performed with type "entity", the API performs a fuzzy search on the database to find any entities who have names similar to the string provided in "search".</p>
                       <p>In addition to the information related to the retrieved entities, the API also shows information about the events and individuals related to these.</p>
                       <p><strong>JSON POST Parameters</strong></p>
                       <table className="table table-hover">
                          <thead>
                             <tr>
                                <th scope="col">FIELD TYPE</th>
                                <th scope="col">TYPE</th>
                                <th scope="col">DESCRIPTION</th>
                             </tr>
                          </thead>
                          <tbody>
                             <tr>
                                <td>search</td>
                                <td>String</td>
                                <td>The keyword to be searched</td>
                             </tr>
                             <tr>
                                <td>key</td>
                                <td>String</td>
                                <td>The unique API key</td>
                             </tr>
                             <tr>
                                <td>type</td>
                                <td>String</td>
                                <td>"entity" for entity search</td>
                             </tr>
                             <tr>
                                <td>country(optional)</td>
                                <td>String</td>
                                <td>The country for which search needs to be done</td>
                             </tr>
                             <tr>
                                <td>search_ratio(optional)</td>
                                <td>String</td>
                                <td>The fuzziness level for the search(defaults to 87.5)</td>
                             </tr>
                             <tr>
                                <td>result_set(optional)</td>
                                <td>String</td>
                                <td>The number of results to retrieve(defaults to 25)</td>
                             </tr>
                          </tbody>
                       </table>
                       <br />

                       <div className="mtr-heading font-size-14">Example Response:</div>
                       <br />
                        <pre>
                         <code>
                        {JSON.stringify(exmResponse3, null, 2) }
                         </code>
                        </pre>

                       <div className="mtr-heading font-size-14">Example Request</div>
                       <p>BODY raw</p>
                       <pre>
                         <code>
                         { JSON.stringify({
                           "search": "hsbc",
                           "key": "3a3a537390084992bef508cc0cca3d29",
                           "type": "entity"
                         }, null, 2) }
                         </code>
                       </pre>
                    </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                </Accordion>
               </div>
              </section>

             <section className="section-pb-50" id="video-section">
               <div className="vi-info-p" >
               <div className="mtr-heading font-size-25 pb-18">User Registeration</div>
               <p><code><a href="https://dummyapi.amlpenalties.com/dummy/dummyregister">https://dummyapi.amlpenalties.com/dummy/dummyregister</a></code></p>
               <p>A user needs to register before he/she can access the search functionality. Upon registration, an email is sent to the provided email id to confirm the identity of the user. The email contains a verification link accessing which generates a unique API key for the user. This key is required to perform search via the API.</p>
               <div className="mtr-heading font-size-16 pb-18">JSON POST Parameters</div>
               <table className="table table-hover">
                 <thead>
                   <tr>
                     <th scope="col">FIELD TYPE</th>
                     <th scope="col">TYPE</th>
                     <th scope="col">DESCRIPTION</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                      <td>name</td>
                      <td>String</td>
                      <td>Name of the user</td>
                   </tr>
                   <tr>
                      <td>email</td>
                      <td>String</td>
                      <td>email of the user</td>
                   </tr>
                   <tr>
                      <td>password</td>
                      <td>String</td>
                      <td>User password</td>
                   </tr>
                   <tr>
                      <td>plan <em>(optional)</em></td>
                      <td>String</td>
                      <td>The subscription plan for the service</td>
                   </tr>
                 </tbody>
               </table>
               <div className="mtr-heading font-size-16 pb-18">JSON POST Parameters</div>
               <table className="table table-hover">
                 <thead>
                   <tr>
                     <th scope="col">Plan</th>
                     <th scope="col">API requests allowed per day</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                      <td>Trial <em>(default)</em></td>
                      <td>10</td>
                   </tr>
                   <tr>
                      <td>Standard</td>
                      <td>50</td>
                   </tr>
                   <tr>
                      <td>Business</td>
                      <td>500</td>
                   </tr>
                   <tr>
                      <td>Enterprise</td>
                      <td>5000</td>
                   </tr>
                 </tbody>
               </table>
               </div>
             </section>

             <section className="section-pb-0" id="icons-section">
               <div className="vi-info-p" >
               <div className="mtr-heading font-size-25 pb-18">API Key Generation</div>
               <p><code><a href="https://dummyapi.amlpenalties.com/dummy/dummykey">https://dummyapi.amlpenalties.com/dummy/dummykey</a></code></p>
               <p>A user who is already registered with the API can regenerate their API key using their email and password. Once the new key is generated, the older key that belonged to that particular user becomes inactive and cant be used to make further requests.</p>
               <div className="mtr-heading font-size-16 pb-18">JSON POST Parameters</div>
               <table className="table table-hover">
                 <thead>
                   <tr>
                     <th scope="col">FIELD TYPE</th>
                     <th scope="col">TYPE</th>
                     <th scope="col">DESCRIPTION</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                      <td>email</td>
                      <td>String</td>
                      <td>email of the user</td>
                   </tr>
                   <tr>
                      <td>password</td>
                      <td>String</td>
                      <td>User password</td>
                   </tr>
                 </tbody>
               </table>
               <div className="mtr-heading font-size-14">Example Request</div>
               <p>BODY raw</p>


                             <pre>
                             <code>
                               { JSON.stringify({
                                       "email": "test@test.tech",
                                       "password": "test"
                                   }, null, 2) }
                                 </code>
                              </pre>
               </div>
             </section>

        </div>
      </div>
        {/* end blog content section */}

     </div>
   </div>
  );
}
}

export default ApiDocs;
