
class ViewSDKClient {
    constructor(url) {
      this.url = url;
     //console.log({url})
      this.readyPromise = new Promise(resolve => {
        if (window.AdobeDC) {
          resolve();
        } else {
     
          document.addEventListener("adobe_dc_view_sdk.ready", () => resolve());
        }
      });
      this.adobeDCView = undefined;
    }
  
    ready() {
      return this.readyPromise;
    }
  
    previewFile(divId, viewerConfig) {
      const config = {
        // clientId: "090cfee248d94257951ea10b2bc5f02b",
        clientId: "153e65a415b4492da3d7e59a8a86d6a3",
      };
      if (divId) {
        config.divId = divId;
      }
    
      this.adobeDCView = new window.AdobeDC.View(config);
  
      const previewFilePromise = this.adobeDCView.previewFile(
        {
         
          content: {
           
            location: {
              url: this.url,
            },
          },
         
          metaData: {
           
            fileName: "AMLPenalties Annual Report 2021 Teaser",
           
            id: "0870e8e9af6d4ba5993071a3a32c2149",
          },
        },
        viewerConfig
      );
  
      return previewFilePromise;
    }
  }
  
  export default ViewSDKClient;
  