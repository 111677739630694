import React from 'react';
import ReactDOM from 'react-dom';
import { hydrate, render } from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import "./i18n";
import * as serviceWorker from './serviceWorker';
import Analytics from 'react-router-ga';
import "babel-polyfill"
const app =(
  <BrowserRouter>
  <Analytics id="UA-154416724-2">
   <App />
   </Analytics>
 </BrowserRouter>
)
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}

serviceWorker.unregister();