import {LandingInstance, AdminInstance, CookiesInstance, HashKeyInstance, BearerInstance} from './axios-file';

import { TICKER,
  SUBJECT_DD,
  CONTACT_US,
  SEND_MAIL_BASE_URL,
  INSIGHTS_URL,
  LATEST_INSIGHTS,
  PLAN_URL,
  KEY_URL,
  INSIGHTS_DETAIL,
  DELETE_INSIGHTS,
  INSIGHT_TYPE,
  REFRESH_TOKEN,
  USER_INFO,
  BLOG_DETAIL,
  BLOG_LIST,
  CRM_ENTRY_BASE_URL
} from './api-endpoints';

export function tickerList() {
  return LandingInstance
    .get(TICKER)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      throw err;
    });
}

export function subjectDDList() {
  return LandingInstance
    .get(SUBJECT_DD)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      throw err;
    });
}

export function contactUs(contactDetail) {
  return HashKeyInstance
    .post(CONTACT_US, { ...contactDetail })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw err;
    });
}
// ------------------|SEND MAIL ENDPOINTS |------------------
export function sendMail(mailData) {
  return LandingInstance.post(SEND_MAIL_BASE_URL,mailData);
}
export function LetestInsights() {
  return LandingInstance
    .get(LATEST_INSIGHTS)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      throw err;
    });
}
export function BlogDetail(name) {
  let url = `${BLOG_DETAIL}${name}`
  return LandingInstance
    .get(url)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
}
export function BlogList(page_no,page_limit) {
  return LandingInstance
    .get(BLOG_LIST, { params: {page_no,page_limit} })
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
}
export function PlanListData() {
  return LandingInstance
    .get(PLAN_URL)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      throw err;
    });
}

export function insightsList(type = "") {
  return CookiesInstance
    .get(INSIGHTS_URL, { params: { type } })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function adminInsightsList(page=`${page}`,type =`${type}`,pagination="yes",page_size="5") {
  return AdminInstance
    .get(INSIGHTS_URL, { params: { type ,pagination,page,page_size} })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function keyVerification(key = "") {
  return LandingInstance
    .post(KEY_URL, { ...key })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function addInsights(data = "") {
  let formData = new FormData();
    for(let x in data){
         formData.append(x, data[x]);
    }
  return AdminInstance
    .post(INSIGHTS_URL, formData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function editInsights(data = "") {
  let formData = new FormData();
    for(let x in data){
         formData.append(x, data[x]);
    }
  return AdminInstance
    .put(INSIGHTS_URL, formData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function deleteInsights(ids = "") {
  return AdminInstance
    .post(`${DELETE_INSIGHTS}`, ids)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function singleInsight(id = "") {
  return AdminInstance
    .get(INSIGHTS_DETAIL, { params: { id } })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function insightsType() {
  return AdminInstance
    .get(INSIGHT_TYPE)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function refreshToken(token="") {
  return AdminInstance
    .post(`${REFRESH_TOKEN}`, token)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function userDetail() {
  return BearerInstance
    .get(USER_INFO)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}
export function CRM_Entry(first_name, last_name, email, subject, mobile,message) {
  return LandingInstance
    .post(CRM_ENTRY_BASE_URL,null,{
      params:{
        first_name,
        last_name,
        email,
        subject,
        mobile,
        message
      }
    })
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
}