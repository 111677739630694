import React from 'react';
import bg from '../assets/images/landing/heading.jpg';
import Mailchimp from 'react-mailchimp-form';


export default function Subscribe(props) {

   // let emailId = props && props.location && props.location.state && props.location.state.email;


  return (
    <div className="home-page-conatiner">
      {/* start page title section */}
       <div className="container main-container">
        <div className="row">
           <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
            <h1>SUBSCRIBE TO NEWSLETTER</h1>
           </div>
           <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
              <span className="font-color-grey font-size-14">
                Sign up for our weekly newsletter to get the latest news and stay updated in the world of anti-money laundering.
              </span>
            </div>
        </div>
        {/* end page title section */}

      {/* start post content section */}
      <div className="row pt-80">
        <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
           <h3 className="h3-text font-size-20">AML PENALTIES WEEKLY BULLETIN</h3>
        </div>
      </div>
      <div className="row pt-50">
        <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
        <div className="mailchimp-form" id="">
        <Mailchimp
          action='https://amlpenalties.us20.list-manage.com/subscribe/post?u=d454f63cc9f49ba8caa3b7922&amp;id=fc12608fdf'

          //Adding multiple fields:
          fields={[
            {
              name: 'MMERGE1',
              placeholder: 'First Name*',
              type: 'text',
              required: true,
            },
            {
              name: 'MMERGE2',
              placeholder: 'Last Name*',
              type: 'text',
              required: true,
            },
            {
              name: 'EMAIL',
              placeholder: 'Email*',
              type: 'email',
              required: true
            },
            {
              name: 'MMERGE3',
              placeholder: 'Organization',
              type: 'text',
              required: false
            }
          ]}
          // Change predetermined language
          messages = {
            {
              sending: "Sending...",
              success: "Thank you for subscribing!",
              error: "An unexpected internal error has occurred.",
              //empty: "You must write an e-mail.",
              empty: "All fields are required.",
              duplicate: "Too many subscribe attempts for this email address",
              button: "SUBSCRIBE"
            }
          }
          buttonClassName=""
          className='mailchimp-btn'

        />
        </div>
        </div>
        <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
          <img className="subscribe-img" src={require("../assets/images/new-images/subscribe.png")} />
        </div>
      </div>

      {/*<div className="subscribe-box">

      <section className="wow fadeIn" id="start-your-project" style={{visibility:"visible",animationName: "fadeIn",background: "#1b3272"}}>
        <div className="container" style={{padding: "0 15%"}}>

            <div id="mc_embed_signup">
                <img src={require("../assets/images/landing/Banner2.png")} style={{width: "100%"}} /><br/>
                <br/>
                <p style={{padding: "15px 15px 0"}}>The AML Zone Weekly Bulletin contains a summary of the weeks top Anti-Money Laundering events, news and regulatory updates, to help you stay informed. Our aim is to empower the community made up of Anti-Money Laundering Professionals,
                    Financial Crimes Compliance Experts, Banking Executives and Researchers.</p>


                <Mailchimp
                  action='https://amlpenalties.us20.list-manage.com/subscribe/post?u=d454f63cc9f49ba8caa3b7922&amp;id=fc12608fdf'

                  //Adding multiple fields:
                  fields={[
                    {
                      name: 'MMERGE1',
                      placeholder: 'First Name*',
                      type: 'text',
                      required: true,
                    },
                    {
                      name: 'MMERGE2',
                      placeholder: 'Last Name*',
                      type: 'text',
                      required: true,
                    },
                    {
                      name: 'EMAIL',
                      placeholder: 'Email*',
                      type: 'email',
                      required: true
                    },
                    {
                      name: 'MMERGE3',
                      placeholder: 'Organization',
                      type: 'text',
                      required: false
                    }
                  ]}
                  // Change predetermined language
                  messages = {
                    {
                      sending: "Sending...",
                      success: "Thank you for subscribing!",
                      error: "An unexpected internal error has occurred.",
                      //empty: "You must write an e-mail.",
                      empty: "All fields are required.",
                      duplicate: "Too many subscribe attempts for this email address",
                      button: "Subscribe"
                    }
                  }
                  buttonClassName=""
                  className='subscribe-mc'

                />
            </div>

        </div>
      </section>

      </div> */}
      {/* end blog content section */}
      </div>

   </div>
  );
}
