export const DASHBOARD = '/dashboard';
export const EVENTS = '/events';
export const COUNTRY = '/country';
export const SEARCH = '/search';
export const AdvancedSearch = '/global-search';
export const COMING_SOON = '/coming-soon';
export const RESOURCES = '/resources';
export const ABOUT = '/about';
export const USER_PROFILE = '/profile';
export const EVENT_DETAILS = '/event-details';
export const LINKEDIN = '/linkedin';
export const PRIVACY_POLICY = '/privacy-policy';
export const DISCLAIMER = '/disclaimer';
export const ADMIN = '/admin';
export const TERMS = '/terms';
export const ANALYTICS = '/analytics';
export const SIGNUP = "/signup"
export const LOGIN = "/login"

export const HOME_PAGE = '/';
export const INSIGHTS = "/resources"
export const API = "/api"
export const DISCLAIMER_FROM_HOME = "/disclaimer"
export const PRIVACY_POLICY_PAGE = "/privacy-policy"
export const TERMS_PAGE = "/terms-and-conditions"
export const API_DOCS = "/api-docs"
export const SUBSCRIBE = "/subscribe"
export const CPI_DASHBOARD = "/cpidashboard"
export const PLAN = "/plans"
export const CONTACT_US = "/contact-us"

export const VERIFY_KEY = "/verify-key"
export const INSIGHT_LIST = "/insights-list"
export const ADD_INSIGHTS = "/add-insight"
// export const ADD_BLOG = "/add-blog"
export const EDIT_INSIGHTS = "/edit-insight"
export const NOT_FOUND="/page-not-found"
export const BLOG ="/blog"
export const ResourceList ="/resources-list"
export const yearlyReport ="/AML-Penalties-Annual-Report-2021-Teaser"
export const ResourceDetailCaseFile ="/resource-detail/case-files"
export const ResourceDetailWeeklyBulletin ="/resource-detail/weekly-bulletin"
export const ResourceDetailSubjectMatter ="/resource-detail/the-subject-matter"
export const ResourceDetailSpotlight ="/resource-detail/spotlight-event"
export const ResourceDetailMonthlyUpdate ="/resource-detail/monthly-update"
export const ResourceDetailQuaterlyUpdate ="/resource-detail/quarterly-update"
export const ResourceDetailRegulatoryBulletin ="/resource-detail/regulatory-bulletin"