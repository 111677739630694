import React, { PureComponent } from 'react';
import MetaTags from 'react-meta-tags';

class ApiPage extends PureComponent {
  constructor() {
    super();
    this.state = {
    };
  }

  componentDidMount(){}


  render() {

    return (
      <div className="home-page-conatiner">
          <MetaTags>
    <meta id="url" property="og:url" content="https://www.amlpenalties.com/api"></meta>     
    <meta name="keywords" content="Financial Crime Risk Assessment, Aml Risk Management, Money Laundering Integration, Money Laundering Risk Assessment" /> 
    <meta name="description" content="AML Penalties communicates risk assessment, risk management, and Integration as three steps of the Money laundering process. And we keep the data and facts about every money laundering in the world."/>
      <title>Financial Crime Risk Assessment & AML Management | AML Penalties</title>
    </MetaTags>
        {/* start page title section */}
         <div className="container main-container">
          <div className="row">
             <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
              <h1>AML Penalties API</h1>
             </div>
             <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
                <span className="font-color-grey font-size-14">
                Get access to the API for real-time monitoring of AML events. You will access the insights on AML risk, with over 1 million validated data points using our unique, easy-to-use API solution.
                </span>
              </div>
             <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
               <a href="/api-docs" className="btn btn-primary btn-lg-white float-r">VIEW API DOCUMENTATION</a>
             </div>
          </div>
          {/* end page title section */}

         {/* api section */}
          <div className="row row-mt pt-80">
            <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center">
              <div className="card sm-card">
                <div className="card-body">
                  <img className="card-img-top" src={require("../assets/images/new-images/access.jpg")} alt="Card image" />
                  <p className="card-text font-size-14">Access to 1 million data points.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center">
              <div className="card sm-card">
                <div className="card-body">
                  <img className="card-img-top" src={require("../assets/images/new-images/easy.png")} alt="Card image" />
                  <p className="card-text font-size-14">Easy registration & UI integration.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center">
              <div className="card sm-card">
                <div className="card-body">
                  <img className="card-img-top" src={require("../assets/images/new-images/average.jpg")} alt="Card image" />
                  <p className="card-text font-size-14">2-4 Average search call time.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center">
              <div className="card sm-card">
                <div className="card-body">
                  <img className="card-img-top" src={require("../assets/images/new-images/free.png")} alt="Card image" />
                  <p className="card-text font-size-14">Free trial of 10 searches per day.</p>
                </div>
              </div>
            </div>
          </div>
          {/* api section */}

        </div>


     </div>
    )
  }

}


export default ApiPage;
