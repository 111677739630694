import React, { Component } from "react";
// import { loginObj, LOGIN_FIELDS } from '../constants/authentication';
import Login from "./login";
import { Form, Button } from "react-bootstrap";
import * as Auth from "react-linkedin-login-oauth2";
import PubSub from "pubsub-js";
//import axiosInstance, { axiosInstanceProfile } from '../apis/axios-login';
import "../assets/style.css";
import axios from "axios";
import {
  USER_LOGIN,
  USER_REGISTER,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_DELETE,
  USER_PROFILE,
  USER_EMAIL_VERIFY,
  USER_PROFILE_IMAGE_UPLOAD,
  BASE_URL,
  USER_LOGIN_LINKEDIN,
} from "../apis/api-endpoints";
import { getLocalStorage } from "../utils";
import Axios from "axios";
import Cookies from "js-cookie";

export function logina(email, password) {
  const urlParams = new URLSearchParams(window.location.search);
  var code = urlParams.get("referral_code");
  
  if (typeof code === "object") {
    var code = "";
  }
  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      code: code,
    },
  });
  axiosInstance.interceptors.request.use(
    config => {
      const newConfig = { ...config };
      return newConfig;
    },
    error => {
      Promise.reject(error);
    }
  );
  return axiosInstance
    .post("https://clientdevapiv2.amlpenalties.com/api/v1/login", {
      email,
      password,
    })
    .then(res => {
      
      return res.data;
    })
    .catch(err => {

      if (err.response.data.message) {
        alert(err.response.data.message);
      } else if (err.response.data.detail) {
        alert("Wrong Login Id or Password");
      } else {
        alert("Something went wrong");
      }
      return err.response;
   
    });
}

export default class login extends Component {
  constructor(props) {
    let loggedIn = false;
    super(props);
    this.state = {
      email: "",
      password: "",
      loggedIn,
    };
    this.onChange = this.onChange.bind(this);
    this.loginUser = this.loginUser.bind(this);
  }
  componentDidMount() {
    const urlPa = new URLSearchParams(window.location.search);
    var key = urlPa.get("key");
  
    if (typeof key === "object") {
      var key = "";
    } else {
      var data = null;

      var xhr = new XMLHttpRequest();

      xhr.addEventListener("readystatechange", function() {
        if (
          this.readyState === 4 &&
          (this.status == 200 || this.status == 208)
        ) {
          var result = JSON.parse(this.responseText);
          alert(result.message);
        } else if (this.status == 500) {
          alert("Token expired");
        } else if (this.status == 400) {
          alert("Email Unverified");
        } else {
          var result = JSON.parse(this.responseText);
          
        }
      });

      xhr.open(
        "GET",
        "https://clientdevapiv2.amlpenalties.com/api/v1/user/confirm_email"
      );
      xhr.setRequestHeader("key", key);

      xhr.send(data);
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  loginUser(e) {
    e.preventDefault();
    const { email, password } = this.state;
    logina(email, password).then(res => {
      if (res.data.success == "True") {
        let cookies = Cookies.get("redirect_url");
        if (cookies) {
          //  Cookies.set('access_token', res.data['access']);
          Cookies.set("access_token", res.data["access"], {
            domain: ".amlpenalties.com",
          });
          window.location.replace("http://landing.amlpenalties.com/resources");
          Cookies.remove("redirect_url", { domain: ".amlpenalties.com" });

          Cookies.set("email", res.data.email, { domain: ".amlpenalties.com" });
          Cookies.set("userId", res.data.user_id, {
            domain: ".amlpenalties.com",
          });
          // Cookies.set('profile', JSON.stringify(res.data), { domain: '.amlpenalties.com' });
          // Cookies.set('tk', res.data.access, { domain: '.amlpenalties.com' });

          window.sessionStorage.setItem("email", res.data.email);
          window.sessionStorage.setItem("userId", res.data.user_id);
          window.sessionStorage.setItem("profile", JSON.stringify(res.data));
          window.sessionStorage.setItem("tk", res.data.access);
        } else {
          window.sessionStorage.setItem("email", res.data.email);
          window.sessionStorage.setItem("userId", res.data.user_id);
          window.sessionStorage.setItem("profile", JSON.stringify(res.data));
          window.sessionStorage.setItem("tk", res.data.access);
          //
          window.sessionStorage.setItem("tested", "ok");
          window.location.replace(
            "/dashboard?userid=" +
              res.data.user_id +
              "&token=" +
              res.data.access +
              "&email=" +
              res.data.email
          );

          this.setState({
            loggedIn: true,
          });

          if (res.data.links[0].href === "http://amlpenalties.com/") {
          
            window.location.replace(
              "/dashboard?userid=" +
                res.data.user_id +
                "&token=" +
                res.data.access +
                "&email=" +
                res.data.email
            );
          }
       
          else {
            alert("Something went Wrong, Please Try again");
          }
        }
      } else if (res.message) {
        alert(res.message);
      }
    });
  }

  render() {
    return (
      <div className="formpage">
        <header>
          <nav className="navbar navbar-default bootsnav background-white header-light navbar-top navbar-expand-lg on no-full">
            <div className="container-fluid nav-header-container">
              <div className="col-auto pl-0">
                <a href="https://www.amlpenalties.com" className="logo">
                  <img
                    src="https://www.amlpenalties.com/assets/images/logo.png"
                    data-rjs="images/logo@2x.png"
                    className="logo-dark default"
                    alt="aml"
                    data-rjs-processed="true"
                  />
                  <img
                    src="https://www.amlpenalties.com/assets/images/logo.png"
                    data-rjs="images/logo-white@2x.png"
                    alt="aml"
                    className="logo-light"
                    data-rjs-processed="true"
                  />
                </a>
              </div>

              <div className="col accordion-menu pr-10 pr-md-3">
                <button
                  type="button"
                  className="navbar-toggler collapsed"
                  data-toggle="collapse"
                  data-target="#navbar-collapse-toggle-1"
                  aria-expanded="false"
                >
                  <span className="sr-only">toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <div
                  className="navbar-collapse collapse justify-content-end"
                  id="navbar-collapse-toggle-1"
                >
                  <ul
                    id="accordion"
                    className="nav menulinks navbar-nav navbar-left no-margin alt-font text-normal"
                    data-in="fadeIn"
                    data-out="fadeOut"
                  >
                    <li class="">
                      <a href="https://www.amlpenalties.com/">Home</a>
                    </li>
                    <li className="dropdown simple-dropdown topm">
                      <a href="#">Insights</a>
                      <ul className="dropdown-menu animated" role="menu">
                        <li className="dropdown">
                          <a href="https://www.amlpenalties.com/newsletter.html">
                            Weekly Bulletin
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="https://www.amlpenalties.com/casefiles.html">
                            Case Files{" "}
                          </a>
                        </li>

                        <li className="dropdown">
                          <a href="https://www.amlpenalties.com/subjectmatter.html">
                            The Subject Matter{" "}
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="https://www.amlpenalties.com/visualization.html">
                            The visualization{" "}
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="">
                      <a
                        class="down1"
                        href="https://www.amlpenalties.com/api.html"
                      >
                        API
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>

        <section className="bgadd">
          <div className="wow fadeIn w40">
            <h1>Member Login</h1>
            <form onSubmit={this.loginUser}>
              <input
                type="text"
                name="email"
                placeholder="Username"
                value={this.state.email}
                onChange={this.onChange}
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.onChange}
              />
              <p className="text-right">
                {" "}
                <a href="#">Forgot Password</a>
              </p>
              <button>Submit</button>
              <br />
              <br />
              <p className="text-center">
                Do not have an Account? <a href="/signup">Sign Up here </a>
              </p>
            </form>
            {/* <p className="text-center"><b> To request for a demo, please send us a request at <a href="mailto:contact@aml.zone">contact@aml.zone</a></b></p> */}
          </div>
        </section>

        <footer className="footer-classic-dark background-black  sm-padding-20px-bottom">
          <div className="container">
            <div className="footer-bottom ">
              <div className="row">
                <div className="col-md-6 text-md-left text-small text-center">
                  <a
                    href="https://www.amlpenalties.com/disclaimer.html"
                    target="_blank"
                  >
                    Disclaimer
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a
                    target="_blank"
                    href="https://www.amlpenalties.com/privacy.html"
                  >
                    Privacy Policy
                  </a>{" "}
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a
                    target="_blank"
                    href="https://www.amlpenalties.com/terms.html"
                  >
                    Terms Of Services
                  </a>
                </div>
                <div className="col-lg-6 col-md-6 text-small text-md-right text-center">
                  © Copyright 2020 AML Penalties
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
