import React, { PureComponent } from "react";
import { Link, withRouter, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { refreshToken, userDetail } from '../../../apis/home-api';
import axios from "axios";
import WidgetMenu from "../WidgetComponent/WidgetMenu";



class CommonHeader extends PureComponent {

  constructor(props) {

    super(props);
    this.state = {
      fullName: window.sessionStorage.getItem('name'),
      accessToken:"",
      userData:{}
    };

  }

  componentDidMount(){

  //  let ref_token_access = Cookies.get('refresh_token');
    let ref_token_access = Cookies.get('remove_id');

    if(ref_token_access){
        if(!window.sessionStorage.getItem('access_token'))
          this.getRefreshToken(ref_token_access);
     }else{
       //sessionStorage.clear();
       sessionStorage.removeItem('access_token');
       sessionStorage.removeItem('name');
       sessionStorage.removeItem('link');
       sessionStorage.removeItem('userId');
     }
  }

  getRefreshToken=(token)=>{

    let _this = this;

    refreshToken({'refresh':token})
    .then(res => {
        res = res.data;
        window.sessionStorage.setItem('access_token', res.access);
        this.setState({accessToken:res.access});
       
      if(res.access){
       setTimeout(function () {
         _this.getUserDetail(res.access);
       }, 2000);
      }
    })
    .catch(err => {
      throw err;
    });
  }

  getUserDetail=(token)=>{
    const axiosInstance = axios.create({
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer `+token,
      },
    });
    axiosInstance.interceptors.request.use(
      config => {
        const newConfig = { ...config };
        return newConfig;
      },
      error => {
        Promise.reject(error);
      }
    );
        return axiosInstance
        .get("https://clientprodapiv2.amlpenalties.com/api/v1/user")
        .then(res => {
          if(res.data && res.data.message){
             this.setState({userData:res.data.message});
             window.sessionStorage.setItem('name', res.data.message.first_name);
            // if(res.data.message.links.href === "http://amlpenalties.com/"){
            //   window.sessionStorage.setItem('link', 'https://demo.amlpenalties.com/');
            // }else{
             
            //}
          }
        })
        .catch(err => {
          throw err;
        });
  }


  getLogout=(e)=>{
    e.preventDefault();
    sessionStorage.clear();
    // Cookies.remove('app_id', { domain: 'localhost' });
    // Cookies.remove('remove_id', { domain: 'localhost' });
    // Cookies.remove('temp_id', { domain: 'localhost' });
    // Cookies.remove('u_id', { domain: 'localhost' });
    // sessionStorage.removeItem('token');

     Cookies.remove('app_id', { domain: '.amlpenalties.com' });
     Cookies.remove('remove_id', { domain: '.amlpenalties.com' });
     Cookies.remove('temp_id', { domain: '.amlpenalties.com' });
     Cookies.remove('u_id', { domain: '.amlpenalties.com' });

     // Cookies.remove('redirect_url', { domain: '.amlpenalties.com' });
     // Cookies.remove('refresh_token', { domain: '.amlpenalties.com' });
     // Cookies.remove('userId', { domain: '.amlpenalties.com' });
     window.location.reload();
  }

  launchAppFun=(e)=>{
    // let link = window.sessionStorage.getItem('link');
   
      window.location.replace('https://app.amlpenalties.com/verify');
      // window.location.replace('http://localhost:3000/verify');


  }

  redirectToLogin=(e)=>{
    window.location.replace('https://app.amlpenalties.com/');
  }

  getContactPage=(e)=>{
    window.location.replace('/contact-us');
  }


  render() {

     const {userData} = this.state;
     const {userInfo} = this.props;
     let username = "";
     let resourcename=sessionStorage.getItem('insight-type')?sessionStorage.getItem('insight-type'):"Weekly Bulletin"
     // if(userInfo && userInfo.first_name){
     //    username = userInfo.first_name+' '+userInfo.last_name;
     // }

     if(window.sessionStorage.getItem('name')){
        username = window.sessionStorage.getItem('name');
     }

     // src="https://www.amlpenalties.com/assets/images/logo.png"

    return (
      <>
      <div className="formpage main-hb header-main">
         <header>
            <nav className="navbar navbar-expand-lg navbar-default bootsnav background-white header-light navbar-top on no-full">
               <div className="container-fluid nav-header-container  header-padding">
                     <a href="/" className="logo navbar-brand custom">
                     <img src={require("../../../assets/images/Logo 4.png")} data-rjs="images/logo@2x.png" className="logo-dark default" alt="aml" data-rjs-processed="true"/></a>
                     <button type="button" className="navbar-toggler collapsed bnt-mob" data-toggle="collapse" data-target="#navbar-collapse-toggle-1" aria-expanded="false">
                     <span className="sr-only">toggle navigation</span>
                     <span className="icon-bar"></span>
                     <span className="icon-bar"></span>
                     <span className="icon-bar"></span>
                     </button> 
                 {/*    <button type="button" className="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbar-collapse-toggle-1" aria-expanded="false">
                      <span className="navbar-toggler-icon"></span>
                     </button>*/}
                     <div className="navbar-collapse collapse justify-content-end left-mobi bg-white" id="navbar-collapse-toggle-1">
                        <ul id="accordion" className="nav menulinks navbar-nav navbar-left no-margin alt-font text-normal" data-in="fadeIn" data-out="fadeOut">
                           <li className="">
                              <Link className="down1" to="/">Home</Link>
                           </li>
                           <li className="">
                              <Link className="down1" to={`/resources`}>Resources</Link>
                              {/* <span className="purpleBadge">NEW</span> */}
                           </li>
                           <li className=""><Link className="down1" to="/api">API</Link>
                           </li>
                           <li className=""><Link className="down1 " to="/plans">Pricing</Link>
                           </li>
                           {/* <li className=""><Link className="down1" to="/contact-us">Contact</Link>
                           </li> */}
                           {/*<li className=""><button type="button" className="btn btn-primary sm-white mtm-3" onClick={this.getContactPage}>Contact</button>
                           </li> */}




                           { Cookies.get('remove_id') && window.sessionStorage.getItem('access_token') ?
                           <>
                             <li className="dropdown simple-dropdown">
                                {/* <a className="down1"><i className="fa fa-user-circle-o"></i> {username?username:(userData.first_name?(userData.first_name):"")}</a> */}
                                <a className="down1 p-img"><img src={require("../../../assets/user.png")} /> &nbsp;{username?username:(userData.first_name?(userData.first_name):"")}</a>
                                <ul className="dropdown-menu animated" role="menu">
                                   <li className="dropdown"><a className="down1" href="javascript:void(0)" onClick={(e)=>this.launchAppFun(e)}>Launch App</a>
                                   </li>
                                   <li className="dropdown"><a className="down1" href="javascript:void(0)" onClick={(e)=>this.getLogout(e)}>Logout</a>
                                   </li>
                                </ul>
                             </li>
                             <li><WidgetMenu /></li>
                             </>
                              :
                             <>
                             <li className=""><button type="button" className="btn btn-primary sm-white  ml-3 " onClick={this.redirectToLogin}>Login</button>
                             </li>
                             {/* <li className=""><button type="button" className="btn btn-primary sm-white mtm-3 btn-w-135" onClick={this.getContactPage}>Request Demo</button>
                             </li> */}
                                <li className="nav-item">
                                    <button
                                      className="btn btn-primary sm-primary  login-desi ml-3"
                                      onClick={()=>this.props.openBot(true)}
                                              >
                                              Book a Free Demo 
                                              </button>
                                </li>
                             <li><WidgetMenu /></li>
                             </>
                           }

                        </ul>
                     </div>
                  </div>
            </nav>
         </header>
      </div>

      </>
    );
  }
}

export default CommonHeader;
