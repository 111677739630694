import React, { PureComponent } from "react";
import CommonHeader from "../components/common/landing/common-header";
import CommonFooter from "../components/common/landing/common-footer";

import "react-image-lightbox/style.css";
import Cookies from "js-cookie";
import axios from "axios";

import MetaTags from 'react-meta-tags';
import InnerInsights from "../components/common/landing/home-insights";
// import ResourcePage from "../components/common/landing/resourcePage";
const TAB = {
  WEEKLY: "Weekly Bulletin",
  CASEFILE: "Case Files",
  SUBJECT: "The Subject Matter",
  VISUALIZATION: "Visualization",
  
  REPORTS: "Reports",
  BLOG:"Blogs",
  SPOTLIGHT:"SpotLight Event",
  Other:"Other Updates",
  MONTHLY:"Monthly Update",
  Quaterly:"Quarterly Update",
  REGULATORY:"Regulatory Bulletin"
};

class Insights extends PureComponent {
  constructor() {
        sessionStorage.setItem("insight-type", "Weekly Bulletin");
    let sectionType = window.sessionStorage.getItem("insight-type");
    super();

    this.state = {
      currentTab: sectionType ? sectionType : TAB.WEEKLY,
      currentTab1:TAB.MONTHLY,
      photoIndex: 0,
      isOpen: false,
      caseList: [],
      reportsList: [],
      reportsInProgesss: false,
      requestInProgesss: false,
      images: [],
      titles: [],
      description:[],
      btnStatus: false,
      tokenMsg: "",
      userData: "",
      accessToken: "",
      page: 1,
      totalpage:1,
    };
    //this.getUserDetail = this.getUserDetail.bind(this);
  }

  // componentDidMount() {
  //   this.setState({ requestInProgesss: true });
  //   this.getInsightsApi();
  //   this.getReportList();
  // }


  //REPORTS API
  getReportList = () => {
    this.setState({ reportsInProgesss: true });

    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        //Authorization: window.sessionStorage.getItem("access_token"),
      },
    });
    axiosInstance.interceptors.request.use(
      config => {
        const newConfig = { ...config };
        return newConfig;
      },
      error => {
        Promise.reject(error);
      }
    );
    return axiosInstance
      .get(
        "https://clientprodapi.amlpenalties.com/api/cms/report?report_type=2")
      .then(res => {

        if (res.status === 206) {

          this.setState({ reportsList: res.data.data.data, reportsInProgesss: false });
        }
      })
      .catch(err => {
        throw err;
      });

  };
  //EOF REPORTS API
  getInsightsApi = tab => {
    // let {page}=this.state;
    let resourcename= sessionStorage.getItem('insight-type')?sessionStorage.getItem('insight-type'):TAB.WEEKLY;
    this.setState({ requestInProgesss: true });
    let newTab = tab ? tab : (this.state.currentTab==="Other Updates"?TAB.MONTHLY:this.state.currentTab);
    //  if(tab) {page=1; this.setState({page:1})}
    // if(newTab==="Blogs"){
    //   const axiosInstance = axios.create({
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/json",
    //       //Authorization: window.sessionStorage.getItem("access_token"),
    //     },
    //   });
    //   axiosInstance.interceptors.request.use(
    //     config => {
    //       const newConfig = { ...config };
    //       return newConfig;
    //     },
    //     error => {
    //       Promise.reject(error);
    //     }
    //   );
    //   return axiosInstance
    //     .get(
    //       "https://clientdevapiv2.amlpenalties.com/api/cms/content?type=" +
    //       newTab+"&pagination=yes&page="+page+"&page_size=6"
    //     )
    //     .then(res => {
    //       // if (newTab !== this.state.currentTab) return;
    //       if (newTab !== this.state.currentTab&&newTab!==this.state.currentTab1) return;
          
    //       this.setState({ caseList: res.data.data, requestInProgesss: false,  totalpage:res.data.total_page });
    //       let image = [];
    //       let title = [];
    //       let descriptions=[];
    //       if (res && res.data && res.data.data && res.data.data.length > 0) {
    //         res.data.data.map((v, i) => {
    //           image.push(`data:image/png;base64,${v.image_data}`);
    //           title.push(v.heading);
    //           descriptions.push(v.description);
    //         })
    //       }
    //       this.setState({ images: image, titles: title,description:descriptions, tokenMsg: res.data.message });
          
    //     })
    //     .catch(err => {
    //       throw err;
    //     });
    // }

    if (newTab !== "Visualization" && newTab !== "Reports") {
      const axiosInstance = axios.create({
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          //Authorization: window.sessionStorage.getItem("access_token"),
        },
      });
      axiosInstance.interceptors.request.use(
        config => {
          const newConfig = { ...config };
          return newConfig;
        },
        error => {
          Promise.reject(error);
        }
      );
      return axiosInstance
        .get(
          "https://clientprodapi.amlpenalties.com/api/cms/content?type=" +
          newTab,
        )
        .then(res => {
          // if (newTab !== this.state.currentTab) return;
          if (newTab !== this.state.currentTab&&newTab!==this.state.currentTab1) return;
          
          this.setState({ caseList: res.data.data, requestInProgesss: false });
          let image = [];
          let title = [];
          let descriptions=[];
          if (res && res.data && res.data.data && res.data.data.length > 0) {
            res.data.data.map((v, i) => {
              image.push(`data:image/png;base64,${v.image_data}`);
              title.push(v.heading);
              descriptions.push(v.description);
            })
          }
          this.setState({ images: image, titles: title,description:descriptions, tokenMsg: res.data.message });
          
        })
        .catch(err => {
          throw err;
        });
    }
  };

  getCurrentTab = tab => {
    this.setState({ caseList: [], currentTab: tab });
if(tab==="Monthly Update"||tab==="Quarterly Update"){
  this.setState({currentTab:"Other Updates",currentTab1:tab });
  window.sessionStorage.setItem("insight-type", "Other Updates");
} else{window.sessionStorage.setItem("insight-type", tab);}
    if(tab!=="Other Updates"){
    this.getInsightsApi(tab);
    }else{
      this.getInsightsApi(this.state.currentTab1);
    }
    this.setState({ btnStatus: false });
  };
  readMore = e => {
    this.setState({ btnStatus: !this.state.btnStatus });
  };



  loginRedirection = e => {
    // if (this.state.tokenMsg === "Expired or Invalid Token") {
    var date = new Date();
    date.setTime(date.getTime() + 60 * 3000);

    Cookies.set("app_id", "true", {
      expires: date,
      // domain: "localhost", // to localhost
      domain: ".amlpenalties.com",
    });
    //FOR LOCALHOST
    // window.location.replace(
    //   "http://localhost:3000/resources/" +
    //   window.sessionStorage.getItem("insight-type")
    // );
    //EOF LOCALHOST
    // FOR APP
    window.location.replace("https://app.amlpenalties.com/resources/" + window.sessionStorage.getItem("insight-type"));

  };
  // handlePageChange = (event, value) => {
  //   this.setState({ caseList: [],page:value},()=>this.getInsightsApi());
  // };

  render() {
    const {
      photoIndex,
      isOpen,
      caseList,
      images,
      titles,
      btnStatus,
      description,
      userData,
      reportsList,
      page,
      totalpage
    } = this.state; 
    return (
      <div className="home-page-conatiner">
        <MetaTags>
          <meta
            id="url"
            property="og:url"
            content="https://www.amlpenalties.com/resources/Weekly%20Bulletin"
          />
          <meta
            id="image"
            property="og:image"
            itemprop="image"
            content="https://d2utazo5ebtbf3.cloudfront.net/snippet/AML%20Penalties_Dynamic%20URL_Sanyam_Sep2023.png"
            class="img-responsive"
          />
          <meta
            id="image-url"
            property="og:image:url"
            itemprop="image"
            content="https://d2utazo5ebtbf3.cloudfront.net/snippet/AML%20Penalties_Dynamic%20URL_Sanyam_Sep2023.png"
            class="img-responsive"
          />
          <meta name="keywords" content="Money Laundering Fines,Solutions To Money Laundering,Money Laundering International,Banking Money Laundering,Anti Money Laundering Checks,Anti Money Laundering In Banking,Anti Money Laundering Penalties,Anti Money Laundering Fines,Money Laundering Penalties" /> 
        </MetaTags>

        {/* start page title section */}
        <div className="container main-container">
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
              <h1>RESOURCES</h1>
            </div>
            </div>
            {/* {this.state.currentTab === TAB.WEEKLY && (
              <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
                <a
                  href="/subscribe"
                  className="btn btn-primary btn-lg-white float-r"
                >
                  SUBSCRIBE TO NEWLETTER
                </a>
              </div>
            )}
        
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
              <nav className="nav-list">
                <ul>
                  <li
                    className={`${
                      this.state.currentTab === TAB.WEEKLY ? "active" : ""
                    }`}
                    onClick={() => {
                      this.getCurrentTab(TAB.WEEKLY);
                    }}
                  >
                    <a
                      data-toggle="tab"
                      className={`${
                        this.state.currentTab === TAB.WEEKLY ? "active" : ""
                      }`}
                    >
                      Weekly Bulletin
                    </a>
                  </li>
                  <li
                    className={`${
                      this.state.currentTab === TAB.REGULATORY ? "active" : ""
                    }`}
                    onClick={() => {
                      this.getCurrentTab(TAB.REGULATORY);
                    }}
                  >
                    <a data-toggle="tab">Regulatory Bulletin</a>
                  </li>

                  <li
                    className={`${
                      this.state.currentTab === TAB.CASEFILE ? "active" : ""
                    }`}
                    onClick={() => {
                      if (
                        document.location.hostname.search(
                          "https://demo.amlpenalties.com/"
                        ) !== -1
                      ) {
                        ReactGA.pageview(this.state.currentTab);
                      }
                      this.getCurrentTab(TAB.CASEFILE);
                    }}
                  >
                    <a data-toggle="tab">Case Files</a>
                  </li>

                  <li
                    className={`${
                      this.state.currentTab === TAB.SUBJECT ? "active" : ""
                    }`}
                    onClick={() => {
                      this.getCurrentTab(TAB.SUBJECT);
                    }}
                  >
                    <a data-toggle="tab">The Subject Matter</a>
                  </li>
                  <li
                    className={`${
                      this.state.currentTab === TAB.SPOTLIGHT ? "active" : ""
                    }`}
                    onClick={() => {
                      this.getCurrentTab(TAB.SPOTLIGHT);
                    }}
                  >
                    <a data-toggle="tab">Spotlight Events</a>
                  </li>
                  <li
                    className={`${
                      this.state.currentTab === TAB.Other ? "active" : ""
                    }`}
                    onClick={() => {
                      this.getCurrentTab(TAB.Other);
                    }}
                  >
                    <a data-toggle="tab">Other updates</a>
                  </li>
                  <li
                    className={`${
                      this.state.currentTab === TAB.REPORTS ? "active" : ""
                    }`}
                    onClick={() => {
                      this.getCurrentTab(TAB.REPORTS);
                    }}
                  >
                    <a data-toggle="tab">Reports</a>
                  </li>
                  <li
                    className={`${
                      this.state.currentTab === TAB.BLOG ? "active" : ""
                    }`}
                    onClick={() => {
                      this.getCurrentTab(TAB.BLOG);
                    }}
                  >
                    <a data-toggle="tab">Blogs</a>
                  </li>
                  <li
                    className={`${
                      this.state.currentTab === TAB.VISUALIZATION
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      this.getCurrentTab(TAB.VISUALIZATION);
                    }}
                  >
                    <a data-toggle="tab">Visualization</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          {this.state.currentTab === TAB.WEEKLY && (
            <div className="row pt-20">
              {caseList.length > 0 &&
                caseList.map((item, i) => {
                  let link = item.external_link
                    ? item.external_link
                    : "https://mailchi.mp/aml.zone/aml-zone-weekly-bulletin_24_august";
                  return (
                    <div
                      key={i}
                      className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center mb-15"
                    >
                      <div className="card lg-card in-cursor">
                        <a href={link} target="_blank">
                          <img
                            loading="lazy"
                            className="card-img-top"
                            src={`data:image/png;base64,${item.image_data}`}
                            alt="Card image"
                          />
                        </a>
                        <div className="card-body">
                          <p className="card-text font-size-16">
                            {item.heading}
                          </p>
                          <p className="card-pera font-size-12">
                            {format(
                              new Date(item.date_of_action),
                              "d MMMM yyyy"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {this.state.requestInProgesss && (
                <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                  <div className="text-center">
                    <Loader loading />
                  </div>
                </div>
              )}

              <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                <div className="lr-btn-block">
                  <button
                    type="button"
                    className="btn btn-primary sm-primary lr-btn"
                    onClick={(e) => this.loginRedirection()}
                  >
                    {!Cookies.get("remove_id")
                      ? "LOGIN TO SEE MORE"
                      : "CLICK TO SEE MORE"}
                  </button>
                </div>
              </div>
            </div>
          )}

          {this.state.currentTab === TAB.CASEFILE && (
            <div className="row pt-20">
              {caseList.length > 0 &&
                caseList.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center mb-15"
                    >
                      <div className="card lg-card in-cursor">
                        <div
                          onClick={() =>
                            this.setState({ isOpen: true, photoIndex: i })
                          }
                        >
                          <img
                            loading="lazy"
                            className="card-img-top"
                            src={`data:image/png;base64,${item.image_data}`}
                            alt="Card image"
                          />
                        </div>
                        <div className="card-body">
                          <p className="card-text font-size-16">
                            {item.heading.length > 35
                              ? item.heading.substr(0, 35) + "..."
                              : item.heading}
                          </p>
                          <p className="card-pera font-size-12">
                            <b>Date of Action </b>{" "}
                            {format(
                              new Date(item.date_of_action),
                              "d MMMM yyyy"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex:
                        (photoIndex + images.length - 1) % images.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % images.length,
                    })
                  }
                  imageTitle={titles[this.state.photoIndex]}
                />
              )}

              {this.state.requestInProgesss && (
                <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                  <div className="text-center">
                    <Loader loading />
                  </div>
                </div>
              )}

              <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                <div className="lr-btn-block">
                  <button
                    type="button"
                    className="btn btn-primary sm-primary lr-btn"
                    onClick={(e) => this.loginRedirection()}
                  >
                    {!Cookies.get("remove_id")
                      ? "LOGIN TO SEE MORE"
                      : "CLICK TO SEE MORE"}
                  </button>
                </div>
              </div>
            </div>
          )}

          {this.state.currentTab === TAB.SUBJECT && (
            <div className="row pt-20">
              {caseList.length > 0 &&
                caseList.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center mb-15"
                    >
                      <div className="card lg-card in-cursor">
                        <div
                          onClick={() =>
                            this.setState({ isOpen: true, photoIndex: i })
                          }
                        >
                          <img
                            loading="lazy"
                            className="card-img-top"
                            src={`data:image/png;base64,${item.image_data}`}
                            alt="Card image"
                          />
                        </div>
                        <div className="card-body">
                          <p className="card-text font-size-16">
                            {item.heading.length > 35
                              ? item.heading.substr(0, 35) + "..."
                              : item.heading}
                          </p>
                          <p className="card-pera font-size-12">
                            {format(
                              new Date(item.date_of_action),
                              "d MMMM yyyy"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex:
                        (photoIndex + images.length - 1) % images.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % images.length,
                    })
                  }
                  imageTitle={titles[this.state.photoIndex]}
                />
              )}

              {this.state.requestInProgesss && (
                <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                  <div className="text-center">
                    <Loader loading />
                  </div>
                </div>
              )}

              <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                <div className="lr-btn-block">
                  <button
                    type="button"
                    className="btn btn-primary sm-primary lr-btn"
                    onClick={(e) => this.loginRedirection()}
                  >
                    {!Cookies.get("remove_id")
                      ? "LOGIN TO SEE MORE"
                      : "CLICK TO SEE MORE"}
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.state.currentTab === TAB.SPOTLIGHT && (
            <div className="row pt-20">
              {caseList.length > 0 &&
                caseList.slice(0,3).map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center mb-15"
                    >
                      <div className="card lg-card  lg-card1 in-cursor">
                        <div
                          onClick={() =>
                            this.setState({ isOpen: true, photoIndex: i })
                          }
                        >
                          <img
                            loading="lazy"
                            className="card-img-top"
                            src={`data:image/png;base64,${item.image_data}`}
                            alt="Card image"
                          />
                        </div>
                        <div className="card-body">
                          <p className="card-text font-size-16">
                            {item.heading.length > 35
                              ? item.heading.substr(0, 35) + "..."
                              : item.heading}
                          </p>
                          <p className="card-pera font-size-12">
                            {format(
                              new Date(item.date_of_action),
                              "d MMMM yyyy"
                            )}
                          </p>
                          <div className="card-pera1 font-size-12">
                            <ShowMoreText
                              lines={3}
                              more="Show more"
                              less="Show less"
                              anchorClass="font-size-14 hovera mx-2"
                              expanded={false}
                              truncatedEndingComponent={"... "}
                            >
                             { <Markup content={item.description} />}
                            </ShowMoreText>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex:
                        (photoIndex + images.length - 1) % images.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % images.length,
                    })
                  }
                  imageTitle={titles[this.state.photoIndex]}
                  imageCaption={ <Markup content={description[this.state.photoIndex]} />}
                />
              )}

              {this.state.requestInProgesss && (
                <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                  <div className="text-center">
                    <Loader loading />
                  </div>
                </div>
              )}

              <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                <div className="lr-btn-block">
                  <button
                    type="button"
                    className="btn btn-primary sm-primary lr-btn"
                    onClick={(e) => this.loginRedirection()}
                  >
                    {!Cookies.get("remove_id")
                      ? "LOGIN TO SEE MORE"
                      : "CLICK TO SEE MORE"}
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.state.currentTab === TAB.Other && (
            <div className="row">
              <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
                <nav className="plan-tab">
                  <ul>
                    <li
                      className={`${
                        this.state.currentTab1 === TAB.MONTHLY ? "active" : ""
                      }`}
                      onClick={() => {
                        this.getCurrentTab(TAB.MONTHLY);
                      }}
                    >
                      <a data-toggle="tab">Monthly Update</a>
                    </li>
                    <li
                      className={`${
                        this.state.currentTab1 === TAB.Quaterly ? "active" : ""
                      }`}
                      onClick={() => {
                        this.getCurrentTab(TAB.Quaterly);
                      }}
                    >
                      <a data-toggle="tab">Quaterly Update</a>
                    </li>
                  </ul>
                </nav>
              </div>

              {this.state.currentTab1 === TAB.MONTHLY && (
                <>
                  {caseList.length > 0 &&
                    caseList.slice(0,3).map((item, i) => {
                      return (
                        <div
                        key={i}
                        className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center mb-15"
                      >
                        <div className="card lg-card lg-card1 in-cursor">
                          <div
                            onClick={() =>
                              this.setState({ isOpen: true, photoIndex: i })
                            }
                          >
                            <img
                              loading="lazy"
                              className="card-img-top"
                              src={`data:image/png;base64,${item.image_data}`}
                              alt="Card image"
                            />
                          </div>
                          <div className="card-body">
                            <p className="card-text font-size-16">
                              {item.heading.length > 35
                                ? item.heading.substr(0, 35) + "..."
                                : item.heading}
                            </p>
                            <div className="card-pera1 font-size-12">
                            <ShowMoreText
                              lines={3}
                              more="Show more"
                              less="Show less"
                              anchorClass="font-size-14 hovera mx-2"
                              expanded={false}
                              truncatedEndingComponent={"... "}
                            >
                             { <Markup content={item.description} />}
                            </ShowMoreText>
                          </div>
                          </div>
                        </div>
                      </div>
                      );
                    })}

                {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex:
                        (photoIndex + images.length - 1) % images.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % images.length,
                    })
                  }
                  imageTitle={titles[this.state.photoIndex]}
                  imageCaption={ <Markup content={description[this.state.photoIndex]} />}
                />
              )}

                  {this.state.requestInProgesss && (
                    <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                      <div className="text-center">
                        <Loader loading />
                      </div>
                    </div>
                  )}

                  <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="lr-btn-block">
                      <button
                        type="button"
                        className="btn btn-primary sm-primary lr-btn"
                        onClick={(e) => this.loginRedirection()}
                      >
                        {!Cookies.get("remove_id")
                          ? "LOGIN TO SEE MORE"
                          : "CLICK TO SEE MORE"}
                      </button>
                    </div>
                  </div>
                </>
              )}
              {this.state.currentTab1 === TAB.Quaterly && (
                <>
                  {caseList.length > 0 &&
                    caseList.slice(0,3).map((item, i) => {
                      return (
                        <div
                        key={i}
                        className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center mb-15"
                      >
                        <div className="card lg-card lg-card1 in-cursor">
                          <div
                            onClick={() =>
                              this.setState({ isOpen: true, photoIndex: i })
                            }
                          >
                            <img
                              loading="lazy"
                              className="card-img-top"
                              src={`data:image/png;base64,${item.image_data}`}
                              alt="Card image"
                            />
                          </div>
                          <div className="card-body">
                            <p className="card-text font-size-16">
                              {item.heading.length > 35
                                ? item.heading.substr(0, 35) + "..."
                                : item.heading}
                            </p>
                            <div className="card-pera1 font-size-12">
                            <ShowMoreText
                              lines={3}
                              more="Show more"
                              less="Show less"
                              anchorClass="font-size-14 hovera mx-2"
                              expanded={false}
                              truncatedEndingComponent={"... "}
                            >
                             { <Markup content={item.description} />}
                            </ShowMoreText>
                          </div>
                          </div>
                        </div>
                      </div>
                      );
                    })}

                {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex:
                        (photoIndex + images.length - 1) % images.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % images.length,
                    })
                  }
                  imageTitle={titles[this.state.photoIndex]}
                  imageCaption={ <Markup content={description[this.state.photoIndex]} />}
                />
              )}

                  {this.state.requestInProgesss && (
                    <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                      <div className="text-center">
                        <Loader loading />
                      </div>
                    </div>
                  )}

                  <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="lr-btn-block">
                      <button
                        type="button"
                        className="btn btn-primary sm-primary lr-btn"
                        onClick={(e) => this.loginRedirection()}
                      >
                        {!Cookies.get("remove_id")
                          ? "LOGIN TO SEE MORE"
                          : "CLICK TO SEE MORE"}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
           {this.state.currentTab === TAB.REGULATORY && (
             <div className="row pt-20">
             {caseList.length > 0 &&
               caseList.slice(0,3).map((item, i) => {
                 let link = item.external_link
                   ? item.external_link
                   : "https://mailchi.mp/aml.zone/aml-zone-weekly-bulletin_24_august";
                 return (
                   <div
                     key={i}
                     className="col-lg-4 col-md-6 mb-4 mb-md-0 col-wd-2 text-center mb-15"
                   >
                     <div className="card lg-card lg-card1 in-cursor">
                       <a href={link} target="_blank">
                         <img
                           loading="lazy"
                           className="card-img-top"
                           src={`data:image/png;base64,${item.image_data}`}
                           alt="Card image"
                         />
                       </a>
                       <div className="card-body">
                         <p className="card-text font-size-16">
                           {item.heading}
                         </p>
                         <p className="card-pera font-size-12">
                           {format(
                             new Date(item.date_of_action),
                             "d MMMM yyyy"
                           )}
                         </p>
                         <div className="card-pera1 font-size-12">
                            <ShowMoreText
                              lines={3}
                              more="Show more"
                              less="Show less"
                              anchorClass="font-size-14 hovera mx-2"
                              expanded={false}
                              truncatedEndingComponent={"... "}
                            >
                             { <Markup content={item.description} />}
                            </ShowMoreText>
                          </div>
                       </div>
                     </div>
                   </div>
                 );
               })}

             {this.state.requestInProgesss && (
               <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                 <div className="text-center">
                   <Loader loading />
                 </div>
               </div>
             )}

             <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
               <div className="lr-btn-block">
                 <button
                   type="button"
                   className="btn btn-primary sm-primary lr-btn"
                   onClick={(e) => this.loginRedirection()}
                 >
                   {!Cookies.get("remove_id")
                     ? "LOGIN TO SEE MORE"
                     : "CLICK TO SEE MORE"}
                 </button>
               </div>
             </div>
           </div>
          )}
          {this.state.currentTab === TAB.REPORTS && (
            <div className="row mt-150 vi-tab">
              {reportsList.length > 0 &&
                reportsList.map((report) => (
                  <div
                    className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2"
                    key={report.ReportId}
                  >
                    <div className="card reportCard">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-left">
                            <div>
                              <img
                                className="img-fluid"
                                src={`data:image/png;base64,${report.ReportSnippetData}`}
                                alt={report.ReportName}
                              />
                            </div>
                          </div>
                          <div className="col-lg-9 col-md-9 mb-4 mb-md-0 col-wd-2 pl-45">
                            <h4 className="vi-card-bold font-size-20">
                              {report.ReportName}
                            </h4>
                            <p className="vi-card-pera font-size-12">
                              {report.ReportDescription}
                            </p>
                            <a
                              className="text-primary"
                              href={
                                window.sessionStorage.getItem("access_token")
                                ? "https://app.amlpenalties.com/report/H1%202021%20AML%20Penalties%20Report"
                                : "https://app.amlpenalties.com/sign-up"
                              }
                            >
                              Access Full Report
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              {this.state.reportsInProgesss && (
                <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                  <div className="text-center">
                    <Loader loading />
                  </div>
                </div>
              )}
            </div>
          )}
 
            { this.state.currentTab === TAB.BLOG && (
              <div>
             <div className="row pt-20">
             { caseList.length > 0 &&
               caseList.map((item, i) => {
                 return (
                  <>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-wd-2 text-center mb-3">
                    <div className="blog-card">
                    
                     <div className="card lg-card lg-card1 in-cursor">
                     <a
                        href={`/blog/${item.id}`}
                        className="link-blog"
                        title="View more details"
                      >
                          <div>
                            { item.image_data===null?<img
                              loading="lazy"
                              className="card-img-top"
                              src={bg}
                              alt="Card image"
                            />:<img
                            loading="lazy"
                            className="card-img-top"
                            src={`data:image/png;base64,${item.image_data}`}
                            alt="Card image"
                          />}
                          </div>
                          </a>
                          <div className="card-body">
                            <p className="card-text font-size-16">
                              {item.heading}
                            </p>
                            <div className="card-pera1 font-size-12">
                            <ShowMoreText
                              lines={3}
                              more="Show more"
                              less="Show less"
                              anchorClass="font-size-14 hovera mx-2"
                              expanded={false}
                              truncatedEndingComponent={"... "}
                            >
                             { <Markup content={item.description} />}
                            </ShowMoreText>
                          </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </>
                 );
               })}

             {this.state.requestInProgesss && (
               <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                 <div className="text-center">
                   <Loader loading />
                 </div>
               </div>
             )}
              
           </div>
           <div className="row mb-4 mt-2 justify-content-center">
            {   
              !this.state.requestInProgesss  && <Pagination
              count={totalpage}
              page={page}
              showFirstButton={true}
              showLastButton={true}
              onChange={this.handlePageChange}
            />
            }
            
          </div>
           </div>
          )}

          {this.state.currentTab === TAB.VISUALIZATION && (
            <div className="row pt-20 vi-tab">
              <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-left">
                        <div>
                          <img
                            className="card-img-top vi-img"
                            src={require("../assets/images/visualization.png")}
                            alt="Card image"
                          />
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-9 mb-4 mb-md-0 col-wd-2 pl-45">
                        <h4 className="vi-card-bold font-size-20">
                          Global Corruption Impact Dashboard
                        </h4>
                        <p className="vi-card-pera font-size-12">
                          The impact of a country on global corruption and
                          instability Introduction As the world becomes
                          increasingly connected and intertwined, so do the
                          fortunes of various economies and regions. This has
                          become increasingly apparent in areas such as trade,
                          terrorism, social instability, technology and
                          migration. There is growing evidence to support that
                          along with the positive effects of globalization,
                          various negative drivers also impact the way the world
                          operates. Corruption is a very critical part of this
                          dynamic. To visually represent the impact of each
                          country...
                        </p>
                        <a
                          href="/cpidashboard"
                          className="vi-rm-btn font-size-13"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      */}
      <InnerInsights showFour={false}/>
       {/* <ResourcePage /> */}
        </div>
      </div>
    );
  }
}

export default Insights;