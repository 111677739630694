import React from 'react';
import bg from '../assets/images/landing/heading.jpg';

export default function PrivacyPolicyPage() {
  return (
    <div className="home-container root-conatiner">
  

     {/* start page title section */}
      <div className="api-box">
        <section className="wow fadeIn bg-light-gray padding-50px-tb page-title-small top-space api-header" style={{background:"url(" + bg + ")"}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-8 col-md-6 text-center text-md-left">
                        <h1 className="Sectionhead  alt-font text-extra-dark-gray mb-0">Privacy Policy</h1>
                    </div>
                </div>
            </div>
        </section>
       </div>
      {/* end page title section */}

      {/* start post content section */}
      <div className="disc-box">

      <section className="wow fadeIn animated">
        <div className="variant-part">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-lg-12 text-intro">

                        <div className="x_content text-left" style={{padding:"20px"}}>
                            <h4>Privacy Policy for AML Penalties</h4>
                            <p>This Policy describes the information we process to support AML Penalties accessible via www.amlpenalties.com and other products and features offered by Zigram Data Technologies Pvt. Ltd.</p>
                            <p>At AML Penalties, one of our main priorities is to maintain the privacy of our visitors/users. This Privacy Policy also outlines the types of information that is collected by AML Penalties and its intended end use.</p>
                            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us through email at contact@amlpenalties.com</p>
                            <h4>General Data Protection Regulation (GDPR)</h4>
                            <p>The type of information that we collect depends on your use of the Product. You can learn how to access and delete information that we collect by visiting the AML Penalties support page.</p>
                            <p>As the Data Controller of your information Zigram Data Technologies Pvt. Ltd. has the legal basis for collecting and using the personal information outlined in this Privacy Policy. By using our website you have given Zigram
                                Data Technologies Pvt. Ltd. permission to collect the following information</p>
                            <ul>
                                <li>Information and content you provide</li>
                                <li>Location, IP and browser data</li>
                                <li>Device information</li>
                                <li>Your usage of the product</li>
                                <li>Cookies data</li>
                            </ul>
                            <p>Zigram Data Technologies Pvt. Ltd. will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply
                                with our legal obligations, resolve disputes, and enforce our policies.</p>
                            <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please
                                contact us.</p>
                            <p>In certain circumstances, you have the following data protection rights:</p>
                            <ul>
                                <li>The right to access, update or to delete the information we have on you.</li>
                                <li>The right of rectification.</li>
                                <li>The right to object.</li>
                                <li>The right of restriction.</li>
                                <li>The right to data portability</li>
                                <li>The right to withdraw consent</li>
                            </ul>
                            <h4>Log Files</h4>
                            <p>AML Penalties follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include
                                internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable.
                                The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
                            <h4>Cookies and Web Beacons</h4>
                            <p>Like any other website, AML Penalties uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize
                                the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
                            <h4>Privacy Policies</h4>
                            <p>You may consult this list to find the Privacy Policy for each of the advertising partners of AML Penalties.</p>
                            <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on AML Penalties, which are sent directly to users' browser.
                                They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites
                                that you visit.</p>
                            <p>Note that AML Penalties has no access to or control over these cookies that are used by third-party advertisers.</p>
                            <h4>Third Party Privacy Policies</h4>
                            <p>AML Penalties' Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their
                                practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.</p>
                            <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are
                                Cookies?
                            </p>
                            <h4>Children's Information</h4>
                            <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
                            <p>AML Penalties does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact
                                us immediately and we will do our best efforts to promptly remove such information from our records.</p>
                            <h4>Online Privacy Policy Only</h4>
                            <p>Our Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in AML Penalties. This policy is not applicable to any information
                                collected offline or via channels other than this website.</p>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

      </div>
      {/* end blog content section */}

   </div>
  );
}
