import React from 'react'
import Loader from "../components/common/loader/bar-loader";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton
} from "react-share";
import cogoToast from "cogo-toast";
import { Markup } from "interweave";
import moment from "moment";
// import MetaTags from 'react-meta-tags';
function ResourceDetailComponent(props) {
    return (
        <div className='home-page-conatiner x2'>
        {/* <MetaTags>
        <meta property="og:title" content={"AML Penalties- "+props.heading} />
        <meta  id="url" property="og:url" content={window.location.href} />
        <meta property="twitter:title" content={"AML Penalties- "+props.heading}/>
        <meta
          property="twitter:url"
          content={window.location.href} 
        />
        </MetaTags> */}
            <div className=" event-detail-page site-page py-5">
                <div className="container-fluid blog-content-div">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12  pl-4">
                            <div className="row">
                                <div className="col-lg-2 col-md-3 col-sm-12  ">
                                    {props.type ? <a href={"/resources-list/" + props.type} className='display'><i className='mt-5 ml-5 fa fa-arrow-left back-icon'>&nbsp;&nbsp;<span>Go Back</span> </i></a> : ""}
                                    <div className="mt-3">
                                        <div className="blogShareIcons BlogShare d-flex justify-content-center">
                                            {/* <span>Link to be updated</span> */}
                                            <FacebookShareButton
                                                className="margin-top mb-4"
                                                url={window.location.href}
                                            >
                                                <i
                                                    className="fa fa-facebook-square"
                                                    aria-hidden="true"
                                                ></i>
                                            </FacebookShareButton>

                                            <TwitterShareButton
                                                className="mb-4"
                                                url={window.location.href}
                                            >
                                                <i
                                                    className="fa fa-twitter-square"
                                                    aria-hidden="true"
                                                ></i>
                                            </TwitterShareButton>

                                            <LinkedinShareButton
                                                className="mb-4"
                                                url={window.location.href}
                                            >
                                                <i
                                                    className="fa fa-linkedin-square"
                                                    aria-hidden="true"
                                                ></i>
                                            </LinkedinShareButton>
                                            <EmailShareButton
                                                url={window.location.href}>
                                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                            </EmailShareButton>
                                            <img
                                                className="fa fa-link CopyBtn mt-4"
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABQ0lEQVRoge2YTW7CMBBGP6OINiXbShyBTQ+BuAgoHIJduyR3IOo92CBxHbb8loC7ikhR1TrJjDNB89aJnZcv9oxjQEj4FNu6YxxOqQGAKJq8brefG9f7OnUn5uJyNuteb9x3vV6sCGAG16yzcpURLAKUkREuArjKtEAEcJGhFbGYkY73g79lSEUOX2nSlExAv/enycvzNLTWvtcd93fM4JoFyyiajIp1hjSRfO/fHxcfvMnYt/s6Q7zYb9H7/swYdq1mZALmCYa7XZqE3RgwmJcZIV93TpxY64jPZACmRHLKJ1N1F/VQ2f0k46lF4Zfx2GvxynhuGvlkGuh+eWQMZa9VFYpnaMl55H9I6kjVN1o3ySIPk4iKSENFpKEi0lARaaiINFREGioiDRWRhopIg/nfrxsUZ/eHSURFpKEi0lARaXwDInmsdRhBdEUAAAAASUVORK5CYII="
                                                onClick={() => {
                                                    const el = document.createElement("input");
                                                    el.value = window.location.href;
                                                    document.body.appendChild(el);
                                                    el.select();
                                                    document.execCommand("copy");
                                                    document.body.removeChild(el);
                                                    cogoToast.success("Link copied to clipboard");
                                                }}
                                            ></img>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-10 col-md-9 col-sm-12">
                                    {props.loading ? <div className="text-center pt-110 minheight85vh"><Loader loading /></div> : props.noResource ? <div className="text-center pt-80 "><h4>No Resource Found</h4></div> : <>
                                        <div className="event-detail-page__top-section site-page__top-section ResourcePage">
                                            <header className="site-page__header mx-0">
                                                <div className=" padding-top padding-right text-center ">
                                                    <h1 className="site-page__heading">{props.heading}</h1>
                                                </div>
                                            </header>
                                        </div>
                                        <div className=" blog-image-div">
                                            {/* {blogImage!==null&& */}
                                            <div className="blog-detail-image text-center ">
                                                <img src={`${props.image}`} className="img-fluid  "></img>
                                            </div>

                                            {/* } */}
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <h5 className="mt-2 text-muted "> Published Date:&nbsp; <i>{moment(props.date).format(
                                                "DD MMM yyyy"
                                            )}</i></h5>
                                        </div>
                                        <div className="mt-5 mb-5  text-justify blog-content">
                                            <Markup content={props.html} />

                                        </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResourceDetailComponent