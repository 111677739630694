import React from 'react'
import MetaTags from 'react-meta-tags';
import ReportPdfContainer from '../components/pdfViewer/ReportPdfContainer';
import { useState, useEffect } from 'react';
import axios from "axios";
import Loader from "../components/common/loader/bar-loader";
function YearlyReport(props) {
  const [pdfUrl, setPdfUrl] = useState("");
  const [noreport ,setNoReport]= useState(false);
  const [reportInProcess, setReportInProcess] = useState(false);
  const [apidata,setApiData]=useState({});
  useEffect(() => {
    // console.log(window.location.pathname.split('/')[1])
    setReportInProcess(true)
    getReportList(window.location.pathname.split('/')[1])
  }, [])
  const getReportList = (id) => {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        //Authorization: window.sessionStorage.getItem("access_token"),
      },
    });
    axiosInstance.interceptors.request.use(
      config => {
        const newConfig = { ...config };
        return newConfig;
      },
      error => {
        Promise.reject(error);
      }
    );
    return axiosInstance
      .get(
        `${process.env.REACT_APP_NEW_BASE_URL}/cms/report?report_type=2&id=${id}`)
      .then(res => {

        if (res.status === 206) {
          setReportInProcess(false)
          if(res.data.data.data.length==0){
            setNoReport(true);
          }else{
          setApiData(res.data.data.data[0]);
          let url = res.data.data.data[0].ReportDocumentData; //string format
          let _pdfURL = `data:application/pdf;base64,${url}`; //string format // converted in ASCII code 
          setPdfUrl(_pdfURL);
          }
        }else{
          setReportInProcess(false);
          setNoReport(true);
        }
      })
      .catch(err => {
        setReportInProcess(false);
        setNoReport(true);
        throw err;
      });

  };
  window.sessionStorage.removeItem("FlashMessagePopup");
  return (
    <div id="yearly-Report" >
      <MetaTags>
        <meta name="keyword" content="Financial Crime, Due Diligence, Compliance, anti money laundering regulations, AML Laws, AML risk checks, AML Database, AML CFT, Correspondent Banking, money laundering, money laundering Events, money laundering fines, money laundering penalties" />
      </MetaTags>
      {reportInProcess ? <div className='row  pt-110 justify-content-center main-container2'><div className='col-3 text-center'><Loader loading /></div></div> : noreport?<div className='main-container2 pt-110 text-center'>No Report Found</div>: <div className="home-page-conatiner ">

        <div className="container main-container" >
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-2 mb-md-0 col-wd-2 text-center">
              <h1>{apidata.ReportName}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-2 mb-md-0 col-wd-2 text-center">
              <h1 className="ReportSubHeading">AML Penalties Annual Report For The Period Of January, 2021 - December, 2021</h1>
            </div>
          </div>
          <div className="row pt-20">
            <div className="col-lg-12 col-md-12 mb-2 mb-md-0">
              <div className="vi-info-p my-3 text-center">
                <p>{apidata.ReportDescription}</p>
              </div>
            </div>
          </div>
         <div className="row mt-5">
            <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
              <div className='pdf-container' >{pdfUrl && <ReportPdfContainer url={pdfUrl} />}</div>

              <br />
              <a
                className=" btn btn-primary sm-primary read-more-btn mt-5"
                href={
                  // "http://localhost:3000/report/AML%20Penalties%20Annual%20Report%202021"
                  "https://app.amlpenalties.com/sign-up"
                  // "https://devapp.amlpenalties.com/report/AML%20Penalties%20Annual%20Report%202021"
                }
                target='_blank'
              >
                Register Now 
              </a>

            </div>
          </div>
        
          {/* <div className="row mt-5">
                 <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                 <a
                              className=" btn btn-primary sm-primary read-more-btn"
                              href={
                          "http://localhost:3000/report/AML%20Penalties%20Annual%20Report%202021"
                              }
                            >
                              Access Full Report
                            </a>

                 </div>
             </div>
           */}
        </div>
      </div>  }
    </div>
  )
}

export default YearlyReport
