import React, { PureComponent } from 'react';
import { subjectDDList, sendMail ,CRM_Entry} from '../../../apis/home-api';
import cogoToast from "cogo-toast";


class Contact extends PureComponent {
  constructor(props) {
    super(props);

    const navUrl = window.location.href.split('/');

    this.state = {
      navUrl:(navUrl && navUrl[3])?navUrl[3]:'',
      isLoading: true,
      list:[],
      first_name:"",
      last_name:"",
      subject:"",
      sender_email:"",
      recipient_email:"",
      phone:"",
      body:""
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitContact = this.submitContact.bind(this);
  }

  componentDidMount(){
     subjectDDList()
      .then(res => {
        this.setState({ list: res.data, isLoading: false });
      })
      .catch(err => {
        throw err;
      });
  }

  handleChange(e){
    e.preventDefault();
    this.setState({ [e.target.name] : e.target.value });
  };

  submitContact(e){
     e.preventDefault();

     let formData = {};
     formData['name'] = this.state.last_name;
     formData['title'] = this.state.subject;
    // formData['recipient_email'] = "noreply_amlpenalties@zigram.tech,ritesh.mohan@zigram.tech";
    // formData['sender_email'] = "admin.manager@zigram.tech";
     if(this.state.body){
        formData['message'] = '<p>'+this.state.body+'</p><br/><br/>'+
                           '<p><b>User Name:</b> '+this.state.last_name+'</p>'+
                           '<p><b>Email:</b> '+this.state.recipient_email+'</p>'+
                           '<p><b>Subject:</b> '+this.state.subject+'</p>';
     }
     
     sendMail(formData)
       .then((res) => {
        //  cogoToast.success(res.data.message);
         
         // alert(res.message);
         this.setState({
          first_name:"",
          last_name:"",
             subject:"",
             sender_email:"",
             recipient_email:"",
             body:"",
           phone:"",  
         })
       })
       .catch((err) => {
         cogoToast.error(err.message);
       });
       CRM_Entry(this.state.first_name,this.state.last_name,this.state.recipient_email,this.state.subject,this.state.phone,this.state.body)
       .then((res)=>{
         if(res.status===200){
          this.setState({
            first_name:"",
            last_name:"",
               subject:"",
               sender_email:"",
               recipient_email:"",
               body:"",
             phone:"",  
           })
          cogoToast.success("Thank you for reaching out to us. We will get back to you shortly.")
         }
       })
  }

  render() {

    const {list} = this.state;
    const {pageName} = this.props;

    return (
      <>

      <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
       <div className="contact-form">
          <form onSubmit={this.submitContact}>
            <div className="form-group">
              <label htmlFor="email">Full Name</label>
              <input type="text" className="form-control"
                name="last_name"
                id="support-form-name"
                placeholder="Full Name*"
                required
                value={this.state.last_name}
                onChange={this.handleChange}
               />
            </div>
            <div className="form-group">
              <label htmlFor="email">Your Email Address</label>
              <input type="email" className="form-control"
                name="recipient_email"
                id="support-form-email"
                placeholder="Work Email*"
                required
                value={this.state.recipient_email}
                onChange={this.handleChange}
               />
            </div>
            <div className="form-group">
              <label htmlFor="email">Your Phone Number</label>
              <input type="number" className="form-control no-arrow"
               name="phone"
               id="support-phone"
               placeholder="Phone"
                value={this.state.phone}
                onChange={this.handleChange}
               />
            </div>
            <div className="form-group">
              <label htmlFor="pwd">Subject</label>
              <select className="form-control" id="support-form-subject"
              name="subject"
              onChange={this.handleChange}
              value={this.state.subject}
              required>
                  <option value="">Select Subject</option>
                  {list && list.length>0 && list.map(function (item) {
                    return <option key={item.id} value={`AML Penalties - ${item.name}`}>{item.name}</option>
                  })}
              </select>

            </div>
            <div className="form-group">
              <label htmlFor="pwd">Message</label>
              <textarea type="text" rows="5" className="form-control font-size-12"
                 placeholder="Type Your Message Here"
                 name="body"
                 id="support-form-message"
                 value={this.state.body}
                 onChange={this.handleChange}
              ></textarea>
            </div>
            <button type="submit" className="contact-btn">Send a Message</button>
          </form>
        </div>
     </div>
     <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
       {/*<img className="contact-img" src={require("../../../assets/images/new-images/contact.png")} />*/}
       <img className="contact-img" src={require("../../../assets/images/new-images/contact-img/Group 131.svg")} />
     </div>
      </>
    )
  }

}


export default Contact;
