import React, { PureComponent } from 'react';
import MetaTags from 'react-meta-tags';
import { PlanListData } from '../apis/home-api';
import Loader from "../components/common/loader/bar-loader";


const TAB = {
  MONTHLY: "monthly",
  ANNUALLY: "annual",
};


class pricingList extends PureComponent {

  constructor() {
    super();
    this.state = {
      currentTab: TAB.MONTHLY,
      planList:[],
      requestInProgesss: false,
    };
  }

  componentDidMount(){
     this.setState({ requestInProgesss: true});
     PlanListData()
      .then(res => {
        this.setState({ planList: res.data, requestInProgesss: false });
      })
      .catch(err => {
        throw err;
      });
  }


render() {

  const {planList,currentTab} = this.state;
  const filteredBasedOnPlan = planList.filter(function(x) { return x.plan_type ===currentTab });
  const dynamicCardsClass= filteredBasedOnPlan.length==3?"col-lg-4 col-md-3 mb-4 mb-md-0 col-wd-2 text-center":"col-lg-3 col-md-3 mb-4 mb-md-0 col-wd-2 text-center";
 
  return (
    <>
    <MetaTags>
    <meta id="url" property="og:url" content="https://www.amlpenalties.com/plans"></meta>
    <meta name="keywords" content="Anti Money Laundering Program,Anti Money Laundering Compliance Program" /> 
    <meta name="description" content="Anti-money laundering compliance programs are used to uncover the suspicious activity associated with criminal acts, including money laundering and terrorist financing."/>
      <title>Best Anti Money Laundering Compliance Program | AML Penalties</title>
    </MetaTags>
     <div className="home-page-conatiner">
       {/* start page title section */}
        <div className="container main-container">
         <div className="row">
            <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
             <h1>Plans built for teams of all sizes</h1>
            </div>
            <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
               <span className="font-color-grey font-size-14">
                 We offer flexible and customize pricing best suitable to your requirements.
               </span>
             </div>
         </div>
         {/* end page title section */}

         {/* start post content section */}

         <div className="row pt-20">
          <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
            <nav className="plan-tab">
              <ul>
                <li className={''+`${
                    this.state.currentTab === TAB.MONTHLY ? "active" : ""
                  }`}
                  onClick={() => {
                    this.setState({ currentTab: TAB.MONTHLY });
                  }} ><a data-toggle="tab">Monthly Billing</a></li>

                <li className={''+`${
                    this.state.currentTab === TAB.ANNUALLY ? "active" : ""
                  }`}
                  onClick={() => {
                    this.setState({ currentTab: TAB.ANNUALLY });
                  }}><a data-toggle="tab">Annual Billing</a></li>
              </ul>
            </nav>

            {/*<nav className="nav-list">
              <ul>
                <li className={''+`${
                    this.state.currentTab === TAB.MONTHLY ? "active" : ""
                  }`}
                  onClick={() => {
                    this.setState({ currentTab: TAB.MONTHLY });
                  }} ><a data-toggle="tab">Monthly Billing</a></li>

                <li className={''+`${
                    this.state.currentTab === TAB.ANNUALLY ? "active" : ""
                  }`}
                  onClick={() => {
                    this.setState({ currentTab: TAB.ANNUALLY });
                  }}><a data-toggle="tab">Annually Billing</a></li>
              </ul>
            </nav> */}
          </div>
        </div>

         {this.state.currentTab === TAB.MONTHLY &&
           <div className="row pt-20">
           {!this.state.requestInProgesss && planList.length>0 && filteredBasedOnPlan.map((item, i)=>{
              return (
             <div key={i} className={dynamicCardsClass}>
               <div className="card lg-card plan-card">
                 <div className="">
                   <div className="plan-price-tag">
                   <span className="plan-free">{(item.plan_name).replace(/ *\b\S*?Plan\S*\b/g, '')}</span>
                   </div>
                   <div className="card-body">
                     <div className="plan-card-body mt-20 mb-20">

                       { (item.plan_code === "free-plan")?
                       <span className="card-text font-size-16">Free Trial</span>
                       :((item.plan_code==="enterprise-plan" && item.price===0)?
                        <p className="card-text font-size-16">Contact us</p>
                       :
                       <><span className="card-text font-size-16">${item.price}</span><span className="plan-gery-text">/user</span></>)
                       }

                       {/*<p className="card-text font-size-16">{item.plan_name}</p>
                       <p className="card-pera font-size-12">dgdfg</p>*/}
                       {/* <button type="button" className="btn btn-primary mt-20">Buy Now</button> */}
                     </div>
                     <hr/>
                     <div className="plan-body-li">
                       <ul>
                         {item.feature && item.feature.features.length>0 && item.feature.features.map((v, j)=>{
                           return (<li key={j}>{v}</li>)
                           })
                         }
                       </ul>
                     </div>
                     <button
                      onClick={()=>window.location.replace('https://app.amlpenalties.com/sign-up')}
                      type="button" className="btn btn-primary mt-20">SIGN UP TODAY</button>
                   </div>
                 </div>
               </div>
             </div>
               )
              })
            }

         </div>
       }

       {this.state.currentTab === TAB.ANNUALLY &&
         <div className="row pt-20">
         {!this.state.requestInProgesss && planList.length>0 && filteredBasedOnPlan.map((item, i)=>{
            return (
           <div key={i} className={dynamicCardsClass}>
             <div className="card lg-card plan-card">
               <div className="">
                 <div className="plan-price-tag">
                 <span className="plan-free">{(item.plan_name).replace(/ *\b\S*?Plan\S*\b/g, '')}</span>

                 </div>
                 <div className="card-body">
                   <div className="plan-card-body mt-20 mb-20">
                     { (item.plan_code === "free-plan")?
                     <span className="card-text font-size-16">Free Trial</span>
                     :((item.plan_code==="enterprise-plan" && item.price===0)?
                      <p className="card-text font-size-16">Contact us</p>
                     :
                     <><span className="card-text font-size-16">${item.price}</span><span className="plan-gery-text">/user</span></>)
                     }
                     {/*<p className="card-text font-size-16">{item.plan_name}</p>
                     <p className="card-pera font-size-12">dgdfg</p>*/}
                     {/* <button type="button" className="btn btn-primary mt-20">SIGN UP TODAY</button> */}
                   </div>
                   <hr/>
                   <div className="plan-body-li">
                     <ul>
                       {item.feature && item.feature.features.length>0 && item.feature.features.map((v, j)=>{
                         return (<li key={j}>{v}</li>)
                         })
                       }
                     </ul>
                   </div>
                  
                 </div>
                 <button 
                 onClick={()=>window.location.replace('https://app.amlpenalties.com/sign-up')}
                 type="button" className="btn btn-primary mt-20">SIGN UP TODAY</button>
               </div>
             </div>
           </div>
             )
            })
          }
       </div>
     }

     {this.state.requestInProgesss && (
       <div className="row pt-20">
         <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
           <div className="text-center">
             <Loader loading />
           </div>
         </div>
       </div>
     )}
         {/* end blog content section */}

      </div>
    </div>
   </>
  )
}

}


export default pricingList;
