

import React, { Component } from 'react';
import ViewSDKClient from './ViewSDKClient';

class ReportPdfContainer extends Component {
    
    componentDidMount() {
      
        const viewSDKClient = new ViewSDKClient(this.props.url);
        viewSDKClient.ready().then(() => {
            /* Invoke file preview */
            viewSDKClient.previewFile("pdf-div", {
                /* Pass the embed mode option here */
                embedMode: "SIZED_CONTAINER",
                defaultViewMode:"SINGLE_PAGE",
                showDownloadPDF: true,
                showPrintPDF: false,
            });
        });
    }

    render() {
        return <div id="pdf-div" className="sized-container-div"/>;
    }
}

export default ReportPdfContainer;
