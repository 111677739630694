import React from 'react';

function Loader({ loading }) {
  if (loading) {
    return (
      <div className="loader-demo-box">
        <div className="bar-loader">
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
    );
  }
  return null;
}

export default Loader;
