import React from 'react';
import { Switch, Route ,Redirect } from 'react-router-dom';
import {
  HOME_PAGE,
  DASHBOARD,
  EVENTS,
  COUNTRY,
  USER_PROFILE,
  ABOUT,
  SEARCH,
  EVENT_DETAILS,
  LINKEDIN,
  PRIVACY_POLICY,
  DISCLAIMER,
  COMING_SOON,
  RESOURCES,
  ADMIN,
  TERMS,
  ANALYTICS,
  SIGNUP,
  LOGIN,
  API,
  DISCLAIMER_FROM_HOME,
  PRIVACY_POLICY_PAGE,
  TERMS_PAGE,
  API_DOCS,
  INSIGHTS,
  SUBSCRIBE,
  CPI_DASHBOARD,
  PLAN,
  VERIFY_KEY,
  INSIGHT_LIST,
  ADD_INSIGHTS,
  // ADD_BLOG,
  EDIT_INSIGHTS,
  CONTACT_US,
  // BLOG,
  yearlyReport,
  NOT_FOUND,
  ResourceList,
  ResourceDetailCaseFile,
  ResourceDetailWeeklyBulletin,
  ResourceDetailMonthlyUpdate,
  ResourceDetailQuaterlyUpdate,
  ResourceDetailRegulatoryBulletin,
  ResourceDetailSpotlight,
  ResourceDetailSubjectMatter
} from './constants/url';


import Login from './containers/login';

import Landing from './containers/landing-page';
import ApiPage from './containers/api';
import DisclaimerPage from './containers/disclaimer-page';
import PrivacyPolicyPage from './containers/privacy-policy-page';
import TermsPage from './containers/terms&conditions';
import ApiDocs from './containers/api-docs-new';
// import ApiDocs from './containers/api-docs';
// import Insights from './containers/insights';
import Insights from './containers/insights-new';
import Subscribe from './containers/subscribe';
import Cpidashboard from './containers/cpidashboard';
import PlansList from './containers/plans';
import ContactUs from './containers/contact-us';
// import BlogsDetailPage from './containers/blog-page';
import YearlyReport from './containers/yearlyReport';
// import ResourceDetailPage from './containers/Resource-Detail-Page';
import NotFound from './containers/notfound';
import ResourceDetailListPage from './containers/Resource-Detail-list-page';

export default (props) => (
  <Switch>
    <Route exact path={HOME_PAGE}  render={() => <Landing props={props} />} />
    <Route exact path={API} component={ApiPage} />
    <Route exact path={DISCLAIMER_FROM_HOME} component={DisclaimerPage} />
    <Route exact path={PRIVACY_POLICY_PAGE} component={PrivacyPolicyPage} />
    <Route exact path={TERMS_PAGE} component={TermsPage} />
    <Route exact path={API_DOCS} component={ApiDocs} />
    <Route exact path={`${INSIGHTS}`} component={Insights} />
    <Route exact path={SUBSCRIBE} component={Subscribe} />
    <Route exact path={CPI_DASHBOARD} component={Cpidashboard} />
    <Route exact path={PLAN} component={PlansList} />
    <Route exact path={CONTACT_US} component={ContactUs} />
    {/* <Route exact path={VERIFY_KEY} component={VerifyKey} />
    <Route exact path={INSIGHT_LIST} component={InsightList} />
    <Route exact path={ADD_INSIGHTS} component={AddInsights} /> */}
    {/*COMMENT BEFORE DEPLOYING Resource Changes*/}
    {/* <Route exact  path={ADD_BLOG} component={AddBlogs}/> */}
    {/* <Route exact path={`${EDIT_INSIGHTS}/:id`} component={EditInsights} /> */}
    {/* <Route path={`${BLOG}`} component={BlogsDetailPage}/> */}
    <Route   path={`${ResourceList}/:ResourceName`} component={ResourceDetailListPage}/>
    <Route exact path={yearlyReport} component={YearlyReport}/>
    <Route exact path ={NOT_FOUND} component={NotFound}/>
    <Redirect from="*" to="/page-not-found"/>

  </Switch>
);
