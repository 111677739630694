
export const MAIL_BASE_URL = 'https://commonemailservice.zigram.tech/';
export const NEW_BASE_URL = process.env.REACT_APP_NEW_BASE_URL;
export const BASE_URL =process.env.REACT_APP_BASE_URL;
export const CMS_BASE_URL=process.env.REACT_APP_CMS_URL;
// Landing page Endpoints
export const TICKER = `${NEW_BASE_URL}cms/ticker`;
export const SUBJECT_DD = `${NEW_BASE_URL}cms/subject`;
export const LATEST_INSIGHTS = `${NEW_BASE_URL}cms/latest_post`;
export const CONTACT_US = `${MAIL_BASE_URL}send/email_with_hash`;


export const REFRESH_TOKEN = `${BASE_URL}refresh`;
export const USER_INFO = `${BASE_URL}user`;

//Insights Page EndPoints
export const KEY_URL = `${NEW_BASE_URL}cms/key`;
export const INSIGHTS_URL = `${NEW_BASE_URL}cms/content`;
export const INSIGHTS_DETAIL = `${NEW_BASE_URL}cms/single_post`;
export const DELETE_INSIGHTS = `${NEW_BASE_URL}cms/delete_post`;
export const INSIGHT_TYPE = `${NEW_BASE_URL}cms/type`;
export const BLOG_DETAIL=`${NEW_BASE_URL}cms/add_blog_page_get_byname/`
export const BLOG_LIST = `${NEW_BASE_URL}cms/add_blog_page_list_public`
//Plan List End Point
export const PLAN_URL = `${NEW_BASE_URL}cms/plan_list`;

// ------------------|SEND MAIL ENDPOINTS |------------------
export const SEND_MAIL_BASE_URL =`${BASE_URL}user/send_contact`;

//-------------------------|CRM ENDPOINTS ----------------------
export const CRM_ENTRY_BASE_URL =`${CMS_BASE_URL}cms/insert_crm_request`